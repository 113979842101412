






































import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace, State } from 'vuex-class'
import ButtonIcon from '@/components/ButtonIcon.vue'
import IconPresent from '@/assets/img/icons/present.svg?inline'
import IconEye from '@/assets/img/icons/eye.svg?inline'
import Icon3d from '@/assets/img/icons/3d.svg?inline'
import Color from '@/constants/color'
import TheUserMenuToggle from '@/components/TheUserMenuToggle.vue'
import UserMenuItem from '@/components/UserMenuItem.vue'
import MutationRemoteType from '@/constants/mutation-remote-type'
import ModuleNamespace from '@/constants/module-namespace'
import { Watch } from 'vue-property-decorator'

const remote = namespace('remote')

@Component({
    components: {
        ButtonIcon,
        IconPresent,
        IconEye,
        Icon3d,
        TheUserMenuToggle,
        UserMenuItem
    }
})
export default class TheUserMenu extends Vue {
    @State hasPresentingLeader!: boolean
    @State isPresentingLeader!: boolean
    @State uiColor!: Color
    @remote.State displayNav!: boolean
    @remote.State sampleViewerControlsAllowed!: boolean

    @remote.Action startPresenting!: Function
    @remote.Action stopPresenting!: Function

    presentDescriptionAllowed = true

    get presentDescription() {
        return this.presentDescriptionAllowed && this.hasPresentingLeader && !this.isPresentingLeader
            ? this.$t('remote_someone_presenting')
            : false
    }

    onPresentInput(checked: boolean) {
        // prevent a flash of the description (because of the time between the connection and the presentation beginning)
        this.presentDescriptionAllowed = false

        setTimeout(() => {
            this.presentDescriptionAllowed = true
        }, 300)

        if (checked && !this.isPresentingLeader) {
            this.startPresenting().catch(() => {
                // can't presenting
            })
        } else if (!checked && this.isPresentingLeader) {
            this.stopPresenting()
        }
    }

    onDisplayNavInput(checked: boolean) {
        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.DISPLAY_NAV, checked)
    }

    onSampleViewerControlsAllowedInput(checked: boolean) {
        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.SAMPLE_VIEWER_CONTROLS_ALLOWED, checked)
    }

    @Watch('$route')
    onRouteChange() {
        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.SAMPLE_VIEWER_CONTROLS_ALLOWED, false)
    }
}
