// @see https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
export function getHashFromString(value: string) {
    let result = 0,
        i = 0

    const n = value.length

    if (n > 0) {
        while (i < n) result = ((result << 5) - result + value.charCodeAt(i++)) | 0
        result += Number.MAX_SAFE_INTEGER + 1
    }

    return result
}

export function trimSlashes(value: string): string {
    return value.replace(/^\/|\/$/g, '')
}
