



import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import lottie, { AnimationItem } from 'lottie-web'

@Component
export default class LottieAnimation extends Vue {
    @Prop() src!: string
    @Prop({ default: false }) autoplay!: boolean
    @Prop({ default: false }) loop!: boolean
    animation: AnimationItem | null = null
    playing = false

    mounted() {
        window.fetch(this.src).then(data => {
            data.json().then(json => {
                console.debug('⏳ Lottie animation load')
                this.animation = lottie.loadAnimation({
                    container: this.$refs.lottie as Element,
                    renderer: 'svg',
                    loop: this.loop,
                    autoplay: this.autoplay,
                    animationData: json
                })
                // Play was requested before lottie
                // was ready, so we trigger animation now
                if (this.playing) {
                    console.debug('⏳ Lottie animation is loaded: animate!')
                    this.animation.play()
                }
            })
        })
    }

    play() {
        if (!this.playing) {
            if (!this.animation) {
                console.debug('⏳ Lottie animation is not loaded yet')
            }
            this.animation?.play()
            this.playing = true
        }
    }

    stop() {
        this.animation?.pause()
        this.playing = false
    }
}
