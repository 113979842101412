var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{class:[
        _vm.$style.root,
        _vm.props.ordered && _vm.$style.rootIsOrdered,
        _vm.props.shadow && _vm.$style.rootIsShadow,
        _vm.props.active && _vm.$style.rootIsActive,
        _vm.props.visited && _vm.$style.rootVisited,
        _vm.props.complete && _vm.$style.rootIsComplete
    ]},_vm._l(((_vm.props.active || _vm.props.visited) && !_vm.props.complete ? 2 : 1),function(index){return _c('svg',{key:index,class:_vm.$style.svg},[_c('circle',{class:_vm.$style.circle,attrs:{"r":_vm.props.ordered ? 14 : 4,"cx":_vm.props.ordered ? 15 : 5,"cy":_vm.props.ordered ? 15 : 5}})])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }