

















import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Color from '@/constants/color'

@Component
export default class ButtonIcon extends Vue {
    @Prop({ default: Color.DARK }) color!: Color
    @Prop({ default: true, type: Boolean }) filled!: boolean
    @Prop({ default: false, type: Boolean }) large!: boolean
    @Prop({ default: false, type: Boolean }) small!: boolean
    @Prop({ default: false, type: Boolean }) elevated!: boolean
    @Prop({ default: false, type: Boolean }) outlined!: boolean
    @Prop({ default: false, type: Boolean }) disabled!: boolean
    @Prop({ default: false, type: Boolean }) selected!: boolean
    @Prop() href!: string
    @Prop() hover!: string

    mouseEntered: boolean | null = null

    get classNames(): Array<string> {
        const classNames = [
            this.$style.root,
            { [this.$style.rootOutlined]: this.outlined },
            { [this.$style.rootFilled]: this.filled },
            { [this.$style.rootDark]: this.color === Color.DARK },
            { [this.$style.rootLight]: this.color === Color.LIGHT },
            { [this.$style.rootElevated]: this.elevated },
            { [this.$style.rootSelected]: this.selected },
            { [this.$style.rootLarge]: this.large },
            { [this.$style.rootSmall]: this.small }
        ]

        if (this.hover) {
            classNames.push(this.$style['rootHover' + this.hover.charAt(0).toUpperCase() + this.hover.slice(1)])
        }

        if (this.mouseEntered !== null) {
            classNames.push(this.$style[this.mouseEntered ? 'rootMouseEntered' : 'rootMouseLeft'])
        }

        return classNames
    }

    onMouseEnter() {
        this.mouseEntered = true
    }

    onMouseLeave() {
        this.mouseEntered = false
    }
}
