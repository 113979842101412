



import marked from 'marked'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class Markdown extends Vue {
    @Prop() content!: string
    @Prop() inline?: boolean

    get parsedContent(): string {
        if (this.inline) {
            return marked.parseInline(this.content)
        }
        return marked(this.content)
    }
}
