export default class HttpError extends Error {
    code: number
    detail?: string

    constructor(message: string, code: number, detail?: string) {
        super(message)
        this.name = 'HttpError'
        this.code = code
        this.detail = detail
    }
}
