enum SlideType {
    ALIAS = 'SlideAlias',
    BASIC_CAROUSEL = 'SlideBasicCarousel',
    BASIC_CAROUSEL_ITEM = 'SlideBasicCarouselItem',
    COLLECTION = 'SlideCollection',
    COLLECTIONS_CAROUSEL = 'SlideCollectionsCarousel',
    CONTACT = 'SlideContact',
    CONTAINER = 'SlideContainer',
    CUSTOM_ANIMATION = 'SlideCustomAnimation',
    DROPDOWN = 'SlideDropdown',
    FLOATING_IMAGES = 'SlideFloatingImages',
    HEADING = 'SlideHeading',
    INTRO = 'SlideIntro',
    LAYOUT = 'SlideLayout',
    MEDIA = 'SlideMedia',
    MEDIA_GRID = 'SlideMediaGrid',
    PDF_VIEWER = 'SlidePdfViewer',
    SAMPLE_VIEWER = 'SlideSampleViewer',
    SECTION = 'SlideSection',
    TIMELINE_CAROUSEL = 'SlideTimelineCarousel',
    TIMELINE_CAROUSEL_ITEM = 'SlideTimelineCarouselItem'
}

export default SlideType
