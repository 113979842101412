import { GetterTree } from 'vuex'
import { RootState } from '@/@types/store'
import { trimSlashes } from '@/utils/string'
import { RouteConfig } from 'vue-router'
import RouteName from '@/constants/route-name'

const EXCLUDED_ROUTES: Array<RouteName> = [RouteName.NOT_FOUND, RouteName.HOME]

const mutations: GetterTree<RootState, RootState> = {
    getSectionIndexByPath: (state: RootState, getters) => (path: string) => {
        const pathSplit = trimSlashes(path).split('/')
        const rootPath = '/' + pathSplit[0]

        return getters.navRoutes.findIndex((route: RouteConfig) => {
            return route.path === rootPath
        })
    },
    navRoutes: (state: RootState) => {
        function filter(routes: RouteConfig[]): RouteConfig[] {
            return routes
                .filter(route => {
                    return (
                        !EXCLUDED_ROUTES.includes(route.name as RouteName) &&
                        (state.isLeader || route.meta?.isShadowContent !== true)
                    )
                })
                .map(route => {
                    if (route.children) {
                        route.children = filter(route.children)
                    }

                    return route
                })
        }

        return filter(state.routes.slice())
    },
    flatNavRoutes: (state: RootState, getters) => {
        const results: RouteConfig[] = []

        function add(routes: RouteConfig[], path = '') {
            routes.forEach(route => {
                if (route.children) {
                    add(route.children, path + route.path + '/')
                } else {
                    results.push({ ...route, path: path + route.path })
                }
            })
        }

        add(getters.navRoutes)

        return results
    },
    displayNavigation: (state: RootState) => state.presentationWalker?.item.displayMenu !== false,
    displayContextualNavigation: (state: RootState) =>
        state.presentationWalker?.item.displayContextualNavigation !== false,
    displaySampleViewerTour: (state: RootState) => state.presentationWalker?.item.displaySampleViewerTour === true,
    hasNavigation: (state, getters) => {
        return getters.navigationIsAllowed && getters.navRoutes.length > 0 && getters.displayNavigation
    },
    hasContextualNavigation: (state, getters) => {
        return getters.navigationIsAllowed && getters.navRoutes.length > 0 && getters.displayContextualNavigation
    },
    navigationIsAllowed: state => {
        return state.isLeader || !state.hasPresentingLeader
    },
    hasActiveTour: state => {
        return state.activeTourSlideIndexes.length > 0
    }
}

export default mutations
