











import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace, State } from 'vuex-class'
import ButtonIcon from '@/components/ButtonIcon.vue'
import IconGearwheel from '@/assets/img/icons/gearwheel.svg?inline'
import Color from '@/constants/color'
import ModuleNamespace from '@/constants/module-namespace'

const remoteModule = namespace(ModuleNamespace.REMOTE)

@Component({
    components: {
        ButtonIcon,
        IconGearwheel
    }
})
export default class TheUserMenu extends Vue {
    @State isPresentingLeader!: boolean
    @State uiColor!: Color
    @remoteModule.State navIsOpen!: boolean

    get color() {
        return this.navIsOpen ? Color.DARK : this.uiColor
    }
}
