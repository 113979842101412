import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { RootState } from '@/@types/store'
import remote from '@/store/modules/remote'
import createRemotePlugin from '@/store/plugins/remote-plugin'
import { getSocket } from '@/websocket'

Vue.use(Vuex)

const socket = getSocket()
const plugins = []

if (socket) plugins.push(createRemotePlugin(socket))

const store: StoreOptions<RootState> = {
    state,
    mutations,
    actions,
    getters,
    modules: {
        remote
    },
    plugins
}

export default new Vuex.Store<RootState>(store)
