
























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import InputSwitch from '@/components/InputSwitch.vue'

@Component({
    components: {
        InputSwitch
    }
})
export default class UserMenuItem extends Vue {
    @Prop() label!: string
    @Prop() icon!: Vue
    @Prop() value!: string | boolean
    @Prop() description!: string
    @Prop({ default: false }) disabled!: boolean

    checked = this.value

    get classNames() {
        return [
            this.$style.root,
            this.disabled && this.$style.rootIsDisabled,
            this.isSwitch && this.$style.rootSwitch,
            this.isSwitch && this.checked && this.$style.rootSwitchIsActive
        ]
    }

    get innerComponent() {
        return this.isSwitch ? 'label' : 'button'
    }

    get actionComponent() {
        return this.isSwitch ? 'input-switch' : 'span'
    }

    get isSwitch() {
        return typeof this.value === 'boolean'
    }

    onInput(value: boolean) {
        this.checked = value

        this.$emit('input', this.checked)
    }
}
