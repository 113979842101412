










import Component from 'vue-class-component'
import Vue from 'vue'
import { namespace, State } from 'vuex-class'
import { Watch } from 'vue-property-decorator'
import DrawSVGPlugin from 'gsap/DrawSVGPlugin'
import gsap from 'gsap'
import Color from '@/constants/color'
import ModuleNamespace from '@/constants/module-namespace'

gsap.registerPlugin(DrawSVGPlugin)

const remoteModule = namespace(ModuleNamespace.REMOTE)

@Component
export default class TheSlideProgress extends Vue {
    @State slideIndex!: number
    @State numberSlides!: number
    @State uiColor!: Color
    @remoteModule.State navIsOpen!: boolean

    $refs!: {
        circle: SVGCircleElement
    }

    get progress() {
        return Math.min(Math.max(this.slideIndex / (this.numberSlides - 1), 0), 1) || 0
    }

    get isLight() {
        return this.uiColor === Color.LIGHT
    }

    mounted() {
        this.updateCircle(0)
    }

    updateCircle(duration = 0.7) {
        gsap.to(this.$refs.circle, {
            drawSVG: '0 ' + this.progress * 100 + '%',
            duration,
            ease: 'power2.inOut'
        })
    }

    @Watch('progress')
    onProgressChange() {
        this.updateCircle()
    }

    @Watch('navIsOpen')
    onNavIsOpenChange() {
        gsap.to(this.$el, { opacity: this.navIsOpen ? 0 : 1, scale: this.navIsOpen ? 0.9 : 1 })
    }
}
