import * as Sentry from '@sentry/browser'

export function setSentryLocale(locale: string) {
    if (!process.env.VUE_APP_SENTRY_DSN) return

    Sentry.setTag('locale', locale)
}

export function setSentryContext<T>(context: T) {
    if (!process.env.VUE_APP_SENTRY_DSN) return

    Sentry.setContext('token', context)
}
