


















import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import ButtonIcon from '@/components/ButtonIcon.vue'
import ArrowUp from '@/assets/img/icons/arrow-up.svg?sprite'
import ArrowDown from '@/assets/img/icons/arrow-down.svg?sprite'
import { Getter, State } from 'vuex-class'
import eventBus from '@/utils/event-bus'
import EventType from '@/constants/event-type'
import VueRouter, { RouteConfig } from 'vue-router'
import Color from '@/constants/color'

const { isNavigationFailure, NavigationFailureType } = VueRouter

@Component({
    components: {
        ButtonIcon,
        ArrowUp,
        ArrowDown
    }
})
export default class NavContextualButton extends Vue {
    @Prop() direction!: string

    @State('slideIndex') currentSlideIndex!: number
    @State numberSlides!: number
    @State uiColor!: Color

    @Getter navRoutes!: Array<RouteConfig>
    @Getter getSectionIndexByPath!: Function
    @Getter flatNavRoutes!: RouteConfig[]

    get href() {
        return (
            (this.isNextSection || this.isNextSubSection) &&
            typeof this.nextRoute !== 'undefined' &&
            this.nextRoute.path
        )
    }

    get isPrevious() {
        return this.direction === 'previous'
    }

    get isNext() {
        return this.direction === 'next'
    }

    get isDisabled() {
        return this.isPrevious
            ? this.currentSlideIndex === 0
            : this.isLastSlide && (!this.isNextSection || !this.isNextSubSection)
    }

    get isLastSlide() {
        return (
            this.currentSlideIndex === this.numberSlides - 1 ||
            (this.currentSlideIndex === 0 && this.numberSlides === 0)
        )
    }

    get sectionIndex() {
        return this.getSectionIndexByPath(this.$route.path)
    }

    get isSubSection() {
        return 'children' in this.navRoutes[this.sectionIndex]
    }

    get isLastSection() {
        return this.sectionIndex === this.navRoutes?.length - 1
    }

    get isNextSection() {
        return (
            this.isNext &&
            this.isLastSlide &&
            this.nextSectionIndex !== -1 &&
            this.nextSectionIndex !== this.sectionIndex
        )
    }

    get isNextSubSection() {
        return (
            this.isNext &&
            this.isLastSlide &&
            this.nextSectionIndex !== -1 &&
            this.nextSectionIndex === this.sectionIndex
        )
    }

    get nextSectionIndex() {
        if (!this.isNext || !this.isLastSlide || !this.nextRoute) return -1

        return this.getSectionIndexByPath(this.nextRoute.path)
    }

    get currentRouteIndex() {
        return this.flatNavRoutes.findIndex(route => route.path === this.$route.path)
    }

    get nextRoute(): RouteConfig | undefined {
        if (this.currentRouteIndex === -1 && this.$route.path !== '/') return undefined

        return this.flatNavRoutes[this.currentRouteIndex + 1]
    }

    onClick(event: MouseEvent) {
        event.preventDefault()

        if (this.href) {
            this.$router.push(this.href).catch(failure => {
                if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
                    return
                }
            })
        } else {
            const goTo = this.isPrevious ? EventType.GOTO_PREVIOUS_SLIDE : EventType.GOTO_NEXT_SLIDE

            eventBus.$emit(goTo)
        }
    }
}
