enum MutationRemoteType {
    LOCATION = 'location',
    PRESENTING_LOCATION = 'presentingLocation',
    POPIN_DOCUMENT = 'popinDocument',
    VIDEO_IS_PLAYING = 'videoIsPlaying',
    VIDEO_PAUSED = 'videoPaused',
    VIDEO_CURRENT_TIME = 'videoCurrentTime',
    SLIDES = 'slides',
    SINGLE_SLIDE = 'singleSlide',
    NAV_IS_OPEN = 'navIsOpen',
    DISPLAY_NAV = 'displayNav',
    SAMPLE_VIEWER_CONTROLS_ALLOWED = 'sampleViewerControlsAllowed',
    NAV_PATH = 'navPath',
    SECTION_PROGRESSES = 'sectionProgresses',
    SINGLE_SECTION_PROGRESS = 'singleSectionProgress'
}

export default MutationRemoteType
