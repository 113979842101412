






import Vue, { AsyncComponent } from 'vue'
import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import PopinType from '@/constants/popin-type'
import Popin from '@/mixins/Popin'
import ModuleNamespace from '@/constants/module-namespace'

interface PopinComponents {
    [key: string]: AsyncComponent
}

interface AnimatablePopin {
    enter: Function
    leave: Function
}

const popinComponents: PopinComponents = {
    [PopinType.VIDEO]: () => import('./PopinVideo.vue'),
    [PopinType.PDF]: () => import('./PopinPdf.vue')
}

@Component
export default class PopinManager extends Vue {
    @(namespace(ModuleNamespace.REMOTE).State) popinDocument!: RoadizDocument

    $refs!: {
        popin: Popin
    }

    get popinType(): PopinType | null {
        if (!this.popinDocument) return null

        if (this.popinDocument.mimeType === 'application/pdf') {
            return PopinType.PDF
        } else if (
            (this.popinDocument.embedId && this.popinDocument.embedPlatform) ||
            this.popinDocument.mimeType === 'video/mp4'
        ) {
            return PopinType.VIDEO
        }

        return null
    }

    get popinComponent(): AsyncComponent | null {
        return this.popinType ? popinComponents[this.popinType] : null
    }

    get transitionName(): string | null {
        // if (this.popinType === PopinType.VIDEO || this.$refs.popin?.type === PopinType.VIDEO) {
        //     return 'fade'
        // } else {
        //     return null
        // }
        // TODO: fix Transition do not works with css AND js callback
        return null
    }

    onEnter(element: HTMLElement, done: Function) {
        const popin = (this.$refs.popin as unknown) as AnimatablePopin

        if (typeof popin?.enter === 'function') {
            popin.enter().then(() => {
                if (!this.transitionName) done()
            })
        } else {
            if (!this.transitionName) done()
        }
    }

    onLeave(element: HTMLElement, done: Function) {
        const popin = (this.$refs.popin as unknown) as AnimatablePopin

        if (typeof popin?.leave === 'function') {
            popin.leave().then(() => {
                if (!this.transitionName) done()
            })
        } else {
            if (!this.transitionName) done()
        }
    }
}
