


















import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import HttpError from '@/errors/HttpError'
import KeyFigures from '@/components/KeyFigures.vue'
import JwtInfo from '@/components/JwtInfo.vue'
import { getAPIToken } from '@/api'
import { getJWTPayload } from '@/utils/jwt'
import VueI18n from 'vue-i18n'
import IconTime from '@/assets/img/icons/time.svg?sprite'

@Component({
    components: { KeyFigures, JwtInfo, IconTime }
})
export default class ErrorScreen extends Vue {
    @Prop() error!: HttpError | Error

    startTime = 0

    get errorCode(): string {
        if (this.error instanceof HttpError) {
            if (this.error.code === 401) {
                const token = getAPIToken()

                if (token) {
                    const jwtPayload = getJWTPayload<PresentationJWTPayload>(token)
                    const now = Date.now()
                    const startTime = jwtPayload.nbf * 1000
                    const endTime = jwtPayload.exp * 1000
                    const remainingTime = startTime - now

                    if (remainingTime > 0 && endTime > now) {
                        this.startTime = startTime

                        return this.$t('Soon').toString()
                    }
                }
            }

            return this.error.code ? this.error.code.toFixed(0) : '404'
        } else {
            return this.$t('Oops').toString()
        }
    }

    get title(): string | VueI18n.TranslateResult {
        if (this.startTime) {
            return this.$t('Start on') + ' ' + this.$d(this.startTime, 'long')
        }

        return this.$t('An error occurred')
    }

    get message(): VueI18n.TranslateResult {
        if (this.startTime) {
            return this.$t('Your presentation will be available soon')
        }

        return this.$t(this.error.message)
    }

    get hasLink() {
        return this.error instanceof HttpError && [404, 500].includes((this.error as HttpError)?.code)
    }
}
