





































































import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator'
import { RouteConfig, Location as RouterLocation } from 'vue-router'
import Component from 'vue-class-component'
import ChevronRight from '@/assets/img/icons/chevron-right.svg?sprite'
import gsap from 'gsap'
import { Getter, namespace, State } from 'vuex-class'
import ModuleNamespace from '@/constants/module-namespace'
import MutationRemoteType from '@/constants/mutation-remote-type'
import SimpleBar from 'simplebar'
import { getNavRoutePath } from '@/utils/nav'
import { getHashFromString } from '@/utils/string'
import SlideType from '@/constants/slide-type'
import NavLinkStatus from '@/components/NavLinkStatus.vue'

interface GroupedRoutes {
    [key: string]: Array<RouteConfig>
}

interface LinkStatus {
    active: boolean
    visited: boolean
    complete: boolean
    shadow: boolean
}

const remoteModule = namespace(ModuleNamespace.REMOTE)

@Component({
    name: 'NavLinksList',
    components: {
        NavLinkStatus,
        ChevronRight
    }
})
export default class NavLinksList extends Vue {
    @Prop({ default: '' }) path!: string
    @Prop() routes!: Array<RouteConfig>
    @Prop(Boolean) isOrdered!: boolean

    @State isLeader!: boolean
    @State hasPresentingLeader!: boolean
    @remoteModule.State navIsOpen!: boolean
    @remoteModule.State navPath!: string
    @remoteModule.State sectionProgresses!: Record<string, number>
    @remoteModule.State presentingLocation!: RouterLocation

    @Getter navigationIsAllowed!: boolean
    @Getter flatNavRoutes!: RouteConfig[]

    linkHoveredIndex = -1
    linkHoveredRouteIndex = -1
    simpleBar!: SimpleBar
    direction = 1

    timeline!: GSAPTimeline
    $refs!: {
        groupItem: Array<HTMLElement>
    }

    get routePathsByPath(): Record<string, string> {
        return this.routes.reduce((record, route) => {
            record[route.path] = getNavRoutePath(this.path, route)

            return record
        }, {} as Record<string, string>)
    }

    get routeGroups(): Array<Array<RouteConfig>> {
        const groupedByOverTitle = this.routes.reduce<GroupedRoutes>((store, value) => {
            const overTitle = value.meta?.overTitle?.toLowerCase()

            store[overTitle] = store[overTitle] || []
            store[overTitle].push(value)

            return store
        }, {})

        return Object.keys(groupedByOverTitle).map(overTitle => groupedByOverTitle[overTitle])
    }

    get isVisible() {
        return this.navPath === this.path || (!this.hasAnotherMatchingPath && this.path === '')
    }

    get hasAnotherMatchingPath() {
        return this.flatNavRoutes.find(route => route.path.includes(this.navPath))
    }

    get id() {
        return this.getIdByPath(this.path)
    }

    get linkStatusByPath(): Record<string, LinkStatus> {
        const result: Record<string, LinkStatus> = {}

        this.routes.forEach(route => {
            const path = this.routePathsByPath[route.path]
            const presentingLocation = this.presentingLocation?.path
            const active =
                this.hasPresentingLeader && (path === presentingLocation || !!presentingLocation?.startsWith(path))
            const childRoutes = this.routeHasChildrenCapability(route)
                ? this.flatNavRoutes.filter(route => route.path.startsWith(path))
                : null
            const visited = childRoutes
                ? childRoutes.some(
                      route => this.sectionProgresses[route.path] === 0.5 || this.sectionProgresses[route.path] === 1
                  )
                : this.sectionProgresses[path] === 0.5
            const complete = childRoutes
                ? childRoutes.every(route => this.sectionProgresses[route.path] === 1)
                : this.sectionProgresses[path] === 1
            const shadow = route.meta && route.meta.isShadowContent

            result[route.path] = { active, visited, complete, shadow }
        })

        return result
    }

    mounted() {
        // if (this.isVisible) gsap.set(this.$el, { visibility: 'inherit' })
        this.simpleBar = new SimpleBar(this.$el as HTMLElement)
    }

    enter(isNavTransition = false) {
        if (this.timeline) this.timeline.kill()

        this.timeline = gsap
            .timeline()
            .set(this.$el, { visibility: 'inherit' }, 0)
            .fromTo(
                this.getElementsToAnimate(),
                {
                    x: 80 * this.direction,
                    opacity: 0
                },
                {
                    x: 0,
                    opacity: 1,
                    delay: isNavTransition ? 0 : 0.4,
                    duration: 0.7,
                    ease: 'power2.out',
                    stagger: 0.05
                },
                isNavTransition ? 0.4 : 0
            )
    }

    leave(isNavTransition = false) {
        if (this.timeline) this.timeline.kill()

        this.timeline = gsap.timeline()

        if (isNavTransition) {
            this.timeline.to(
                this.getElementsToAnimate(),
                {
                    opacity: 0,
                    duration: 0.4
                },
                0
            )
        } else {
            this.timeline.to(
                this.getElementsToAnimate(),
                {
                    x: -80 * this.direction,
                    opacity: 0,
                    duration: 0.4,
                    ease: 'power2.in',
                    stagger: 0.05
                },
                0
            )
        }

        this.timeline.set(this.$el, { visibility: '' })
    }

    getElementsToAnimate(): NodeList {
        return this.$el.querySelectorAll(`.${this.$style.link}, .${this.$style.groupHead}`)
    }

    getIdByPath(path: string) {
        return this.$options.name?.toLowerCase() + '-' + getHashFromString(path)
    }

    getItemId(route: RouteConfig) {
        return this.getIdByPath(this.routePathsByPath[route.path])
    }

    routeHasChildrenCapability(route: RouteConfig): boolean {
        return route.meta?.type === SlideType.COLLECTIONS_CAROUSEL || route.meta?.type === SlideType.CONTAINER
    }

    @Watch('isVisible')
    onIsVisibleChange() {
        this.$nextTick(() => {
            if (this.isVisible) this.enter()
            else this.leave()
        })
    }

    @Watch('navIsOpen')
    onNavIsOpenChange() {
        this.direction = 1

        if (!this.isVisible) return

        this.$nextTick(() => {
            if (this.navIsOpen) this.enter(true)
            else this.leave(true)
        })
    }

    @Watch('navPath')
    onNavPathChange(newPath: string, oldPath: string) {
        this.direction = newPath.length > oldPath.length ? 1 : -1
    }

    onLinkClick(event: MouseEvent, navigate: Function, route: RouteConfig) {
        if (!this.routeHasChildrenCapability(route)) {
            navigate(event)
            return
        }

        this.$store.commit(
            ModuleNamespace.REMOTE + '/' + MutationRemoteType.NAV_PATH,
            this.routePathsByPath[route.path]
        )
    }

    onLinkMouseEnter(index: number, routeIndex: number) {
        this.linkHoveredIndex = index
        this.linkHoveredRouteIndex = routeIndex
    }

    onLinkMouseLeave() {
        this.linkHoveredIndex = -1
        this.linkHoveredRouteIndex = -1
    }
}
