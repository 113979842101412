
























import Component from 'vue-class-component'
import { Ref, Vue, Watch } from 'vue-property-decorator'
import ButtonIcon from './ButtonIcon.vue'
import Color from '@/constants/color'
import { Getter, namespace, State } from 'vuex-class'
import gsap from 'gsap'
import { RouteConfig } from 'vue-router'
import TheSlideProgress from '@/components/TheSlideProgress.vue'
import CloseIcon from '@/assets/img/icons/cross.svg?inline'
import ModuleNamespace from '@/constants/module-namespace'
import MutationRemoteType from '@/constants/mutation-remote-type'

const remoteModule = namespace(ModuleNamespace.REMOTE)

@Component({
    components: {
        ButtonIcon,
        TheSlideProgress,
        CloseIcon
    }
})
export default class TheNavToggle extends Vue {
    @State uiColor!: Color
    @State presentationIsVisible!: boolean
    @State filteredRoutes!: Array<RouteConfig>
    @remoteModule.State navIsOpen!: boolean

    @Getter getSectionIndexByPath!: Function
    @Getter hasNavigation!: boolean

    @Ref() pictoLines!: Array<HTMLElement>

    get classNames() {
        return [
            this.$style.root,
            this.navIsOpen && this.$style.rootClose,
            this.hasSection && this.$style.rootHasSection
        ]
    }

    get color(): Color {
        return this.uiColor === Color.LIGHT ? Color.DARK : Color.LIGHT
    }

    get sectionIndex() {
        return this.getSectionIndexByPath(this.$route.path)
    }

    get hasSection() {
        return this.sectionIndex !== -1
    }

    enter() {
        gsap.from(this.$el, {
            scale: 0.2,
            opacity: 0,
            delay: 0.6,
            duration: 0.8,
            ease: 'power2.out'
        })

        gsap.from(this.pictoLines, {
            transition: 'none',
            scaleX: 0,
            delay: 0.7,
            duration: 1,
            stagger: 0.1,
            ease: 'power3.inOut',
            onComplete: () => {
                gsap.set(this.pictoLines, { clearProps: 'all' })
            }
        })
    }

    onClick() {
        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.NAV_IS_OPEN, !this.navIsOpen)
    }

    @Watch('presentationIsVisible')
    onPresentationIsVisibleChange() {
        if (this.presentationIsVisible) this.enter()
    }

    @Watch('hasNavigation')
    onHasNavigationChange() {
        // fix persistent close state after the navigation had been deactivated then reactivated
        // while the nav was opened for a customer user
        if (!this.hasNavigation)
            this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.NAV_IS_OPEN, false)
    }
}
