import { MutationPayload, Store } from 'vuex'
import { RemoteState, RootState } from '@/@types/store'
import { getJWTPayload } from '@/utils/jwt'
import EventType from '@/constants/event-type'
import { Socket } from 'socket.io-client'
import MutationType from '@/constants/mutation-type'
import mutations from '@/store/modules/remote/mutations'
import ModuleNamespace from '@/constants/module-namespace'
import MutationRemoteType from '@/constants/mutation-remote-type'

export default function createRemotePlugin(socket: Socket) {
    return function(store: Store<RootState>) {
        if (!socket) return

        const url = new URL(window.location.href)
        const token = url.searchParams.get('token')
        const jwtPayload = token && getJWTPayload<PresentationJWTPayload>(token)
        const isLeader = jwtPayload && jwtPayload.scopes.includes('presentation-leader')
        const leaderRemoteMutations = [
            MutationRemoteType.SINGLE_SECTION_PROGRESS,
            MutationRemoteType.SECTION_PROGRESSES,
            MutationRemoteType.PRESENTING_LOCATION
        ].map(value => value.toString())

        socket.on(EventType.LEADER_PRESENTING_START, (presentingLeaderState: RemoteState) => {
            store.commit(MutationType.HAS_PRESENTING_LEADER, true)

            if (!store.state.isLeader) {
                if (presentingLeaderState) {
                    Object.keys(presentingLeaderState).forEach(key => {
                        if (key in mutations) {
                            store.commit(
                                ModuleNamespace.REMOTE + '/' + key,
                                presentingLeaderState[key as keyof RemoteState]
                            )
                        }
                    })
                }
            } else {
                if (presentingLeaderState && !store.state.isPresentingLeader) {
                    leaderRemoteMutations.forEach(mutation => {
                        store.commit(
                            ModuleNamespace.REMOTE + '/' + mutation,
                            presentingLeaderState[mutation as keyof RemoteState]
                        )
                    })
                }
            }
        })

        socket.on(EventType.LEADER_PRESENTING_STOP, () => {
            store.commit(MutationType.HAS_PRESENTING_LEADER, false)
        })

        socket.on(EventType.PRESENTING_LEADER_DISCONNECT, () => {
            store.commit(MutationType.HAS_PRESENTING_LEADER, false)
        })

        socket.on(EventType.REQUEST_REMOTE_STATE, (name: string, callback: Function) => {
            const remoteState = JSON.parse(JSON.stringify(store.state.remote))

            callback(remoteState)
        })

        if (isLeader) {
            store.subscribe(mutation => {
                if (!store.state.isPresentingLeader || !mutation.type.includes(ModuleNamespace.REMOTE + '/')) return

                socket.emit(EventType.REMOTE_MUTATION, mutation)
            })
        }

        socket.on(EventType.REMOTE_MUTATION, (mutation: MutationPayload) => {
            if (
                !isLeader ||
                (!store.state.isPresentingLeader &&
                    leaderRemoteMutations.includes(mutation.type.replace(ModuleNamespace.REMOTE + '/', '')))
            ) {
                store.commit(mutation.type, mutation.payload)
            }
        })
    }
}
