import { MutationTree } from 'vuex'
import { RootState } from '@/@types/store'
import Color from '@/constants/color'
import { RouteConfig } from 'vue-router'
import MutationType from '@/constants/mutation-type'

const mutations: MutationTree<RootState> = {
    [MutationType.SLUG]: (state, value: string) => (state.slug = value),
    [MutationType.UI_COLOR]: (state, value: Color) => (state.uiColor = value),
    [MutationType.PRESENTATION_IS_VISIBLE]: (state, value: boolean) => (state.presentationIsVisible = value),
    [MutationType.PRESENTATION_WALKER]: (state, value: PresentationWalker) => (state.presentationWalker = value),
    [MutationType.NUMBER_SLIDES]: (state, value: number) => (state.numberSlides = value),
    [MutationType.SLIDE_INDEX]: (state, value: number) => (state.slideIndex = value),
    [MutationType.SLIDE_TYPE]: (state, value: string) => (state.slideType = value),
    [MutationType.ROUTES]: (state, value: Array<RouteConfig>) => (state.routes = value),
    [MutationType.TRANSITION_IS_VISIBLE]: (state, value: boolean) => (state.transitionIsVisible = value),
    [MutationType.TRANSITION_IS_ANIMATED]: (state, value: boolean) => (state.transitionIsAnimated = value),
    [MutationType.IS_LEADER]: (state, value: boolean) => (state.isLeader = value),
    [MutationType.IS_SLIDESHOW]: (state, value: boolean) => (state.isSlideshow = value),
    [MutationType.HAS_PRESENTING_LEADER]: (state, value: boolean) => (state.hasPresentingLeader = value),
    [MutationType.IS_PRESENTING_LEADER]: (state, value: boolean) => (state.isPresentingLeader = value),
    [MutationType.WINDOW_WIDTH]: (state, value: number) => (state.windowWidth = value),
    [MutationType.ADD_ACTIVE_TOUR_SLIDE_INDEX]: (state, value: number) => {
        if (!state.activeTourSlideIndexes.includes(value)) state.activeTourSlideIndexes.push(value)
    },
    [MutationType.REMOVE_ACTIVE_TOUR_SLIDE_INDEX]: (state, value: number) => {
        if (state.activeTourSlideIndexes.includes(value)) {
            state.activeTourSlideIndexes.splice(state.activeTourSlideIndexes.indexOf(value), 1)
        }
    }
}

export default mutations
