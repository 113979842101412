import { RouteConfig } from 'vue-router'
import slideComponents from '@/slide-components.ts'
import SlideType from '@/constants/slide-type'
import RouteName from '@/constants/route-name'
import { resolveId, resolveShadowContent, resolveSlug, resolveTitle } from '@/utils/alias-resolver'

function createChildRoute(
    data: PresentationWalker,
    index = -1,
    parent?: PresentationWalker,
    parentIndex = -1,
    root?: PresentationWalker
): RouteConfig {
    const slug: string = resolveSlug(data)
    const id: string = resolveId(data)

    return {
        path: (parent ? '' : '/') + slug,
        meta: {
            title: resolveTitle(data),
            overTitle: data.item.overTitle,
            id,
            index,
            parent: parent ? { id: resolveId(parent) } : undefined,
            root: root ? { id: resolveId(root) } : undefined,
            parentTitle: parent ? resolveTitle(parent) : undefined,
            isShadowContent: resolveShadowContent(data),
            parentIndex,
            type: data.item['@type']
        },
        props: {
            id,
            index,
            parentIndex
        },
        component: slideComponents[data.item['@type'] as SlideType]
    }
}

// create routes recursively
function createAllChildRoutes(
    route: RouteConfig,
    child: PresentationWalker,
    childIndex: number,
    root: PresentationWalker
) {
    child.children
        .filter(child => {
            return child.item['@type'] in slideComponents
        })
        .forEach((grandchild, grandchildIndex) => {
            const childRoute = createChildRoute(grandchild, grandchildIndex, child, childIndex, root)

            route.children = route.children || []
            route.children.push(childRoute)

            // propagate the shadow content prop to the children
            if (route.meta?.isShadowContent && typeof childRoute.meta !== 'undefined')
                childRoute.meta.isShadowContent = true

            createAllChildRoutes(childRoute, grandchild, grandchildIndex, root)
        })
}

export default function createRoutes(data: PresentationWalker): RouteConfig[] {
    const routes: Array<RouteConfig> = []
    const homeChild = data.children.find(child => child.item.home) || data.children[0]
    const children = data.children.filter(child => child !== homeChild)

    // home route
    let homeRoute: RouteConfig

    if (homeChild) {
        homeRoute = createChildRoute(homeChild)
    } else {
        const walker: PresentationWalker = {
            children: [],
            item: {
                title: '',
                slug: '',
                '@id': '',
                '@type': SlideType.SECTION
            },
            '@type': ''
        }

        homeRoute = createChildRoute(walker)
    }

    homeRoute.path = '/'
    homeRoute.name = RouteName.HOME
    routes.push(homeRoute)

    // children routes
    children
        .filter(child => {
            // at the first level, only these types are allowed
            return [SlideType.CONTAINER, SlideType.SECTION, SlideType.COLLECTIONS_CAROUSEL].includes(
                child.item['@type'] as SlideType
            )
        })
        .forEach((child, childIndex) => {
            const route = createChildRoute(child, childIndex)

            createAllChildRoutes(route, child, childIndex, child)

            routes.push(route)
        })

    // Not found route
    routes.push({
        path: '*',
        name: RouteName.NOT_FOUND,
        component: () => import('../views/SlideNotFound.vue')
    })

    return routes
}
