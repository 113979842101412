

































import Component from 'vue-class-component'
import Vue from 'vue'
import { Ref, Watch } from 'vue-property-decorator'
import MediaContent from '@/components/MediaContent.vue'
import { Getter, namespace, State } from 'vuex-class'
import gsap from 'gsap'
import { getBreakpointValue, mediaIsMin } from '@/utils/media'
import hidden from 'ally.js/esm/maintain/hidden'
import tabFocus from 'ally.js/esm/maintain/tab-focus'
import { RouteConfig } from 'vue-router'
import MutationRemoteType from '@/constants/mutation-remote-type'
import ModuleNamespace from '@/constants/module-namespace'
import JwtInfo from '@/components/JwtInfo.vue'
import NavLinksList from '@/components/NavLinksList.vue'
import NavBreadcrumbs from '@/components/NavBreadcrumbs.vue'
import { getNavRoutePath } from '@/utils/nav'

const remoteModule = namespace(ModuleNamespace.REMOTE)

interface LinksList {
    routes: RouteConfig[]
    path: string
}

@Component({
    components: {
        NavBreadcrumbs,
        NavLinksList,
        MediaContent,
        JwtInfo
    }
})
export default class TheNav extends Vue {
    @State presentationWalker!: PresentationWalker
    @State transitionIsVisible!: boolean
    @State isLeader!: boolean
    @State hasPresentingLeader!: boolean
    @remoteModule.State displayNav!: boolean

    @Getter('navRoutes') routes!: Array<RouteConfig>
    @Getter navigationIsAllowed!: boolean

    @Ref() overlay!: HTMLElement
    @Ref() backgroundColor!: HTMLElement
    @Ref() backgroundMedia!: MediaContent
    @Ref() linksListWrapper!: HTMLElement
    // @Ref() jwtInfo!: HTMLElement

    hasTransition = false
    allowBreadcrumbsTransition = false

    timeline!: GSAPTimeline
    hiddenHandle!: AllyService | null
    tabFocusHandle!: AllyService | null

    get isOpen() {
        const isOpen = this.$store.state.remote.navIsOpen

        return this.navigationIsAllowed ? isOpen : this.$store.state.remote.displayNav && isOpen
    }

    get backgroundMediaDocument(): RoadizDocument | undefined {
        return this.presentationWalker.item.backgroundMedia?.[0]
    }

    get displayBackgroundMedia() {
        return this.$store.state.windowWidth >= getBreakpointValue('lg')
    }

    get linksLists(): LinksList[] {
        const value: LinksList[] = []

        function add(routes: RouteConfig[], path = '') {
            const linksList = { routes, path }

            value.push(linksList)

            routes.forEach(route => {
                if (route.children) {
                    add(route.children, getNavRoutePath(path, route))
                }
            })
        }

        add(this.routes)

        return value
    }

    mounted() {
        window.addEventListener('keyup', this.onKeyUp)

        if (this.isOpen && !this.navigationIsAllowed) this.enter()
    }

    beforeDestroy() {
        window.removeEventListener('keyup', this.onKeyUp)
    }

    enter() {
        this.backgroundMedia?.play()

        if (this.timeline) this.timeline.kill()

        this.allowBreadcrumbsTransition = false

        this.timeline = gsap
            .timeline({
                onComplete: this.afterEnter
            })

            .set(this.$el, { visibility: 'inherit' })

            .add(() => {
                this.allowBreadcrumbsTransition = true
            }, 0.3)

            .fromTo(
                this.overlay,
                {
                    opacity: 0
                },
                {
                    opacity: 1,
                    duration: 0.8
                },
                0
            )

            .fromTo(
                this.backgroundColor,
                {
                    xPercent: -100
                },
                {
                    xPercent: 0,
                    duration: 0.9,
                    ease: 'power2.inOut'
                },
                0
            )

            .set(
                this.linksListWrapper,
                {
                    autoAlpha: 0
                },
                0
            )

            .set(
                this.linksListWrapper,
                {
                    autoAlpha: 1
                },
                0.5
            )

        // .fromTo(
        //     this.jwtInfo,
        //     {
        //         autoAlpha: 0
        //     },
        //     {
        //         autoAlpha: 1,
        //         duration: 0.8
        //     },
        //     1
        // )

        if (mediaIsMin('lg')) {
            if (this.backgroundMedia) {
                this.timeline.fromTo(
                    this.backgroundMedia.$el,
                    {
                        display: 'block',
                        opacity: 0,
                        scale: 1.2
                    },
                    {
                        opacity: 1,
                        scale: 1,
                        duration: 2.2,
                        ease: 'power3.inOut'
                    },
                    'content-=1'
                )
            }
        }
    }

    afterEnter() {
        this.hasTransition = false

        if (!this.tabFocusHandle) {
            this.tabFocusHandle = tabFocus({
                context: this.$el
            })
        }

        if (!this.hiddenHandle) {
            this.hiddenHandle = hidden({
                context: '#app',
                filter: this.$el
            })
        }
    }

    beforeLeave() {
        if (this.tabFocusHandle) {
            this.tabFocusHandle.disengage()
            this.tabFocusHandle = null
        }
    }

    leave() {
        this.backgroundMedia?.pause()

        if (this.timeline) this.timeline.kill()

        this.beforeLeave()

        this.timeline = gsap
            .timeline({
                onComplete: this.afterLeave
            })

            .to(
                this.backgroundColor,
                {
                    xPercent: -100,
                    duration: 0.9,
                    ease: 'power2.inOut'
                },
                0.2
            )

            .to(
                this.overlay,
                {
                    opacity: 0,
                    duration: 0.9
                },
                0.2
            )

            .set(
                this.linksListWrapper,
                {
                    autoAlpha: 0
                },
                0.4
            )

            // .to(
            //     this.jwtInfo,
            //     {
            //         autoAlpha: 0,
            //         duration: 0.4
            //     },
            //     0
            // )

            .set(this.$el, { visibility: '' })

        if (mediaIsMin('lg')) {
            if (this.backgroundMedia) {
                this.timeline.to(
                    this.backgroundMedia.$el,
                    {
                        opacity: 0,
                        duration: 0.4,
                        onComplete: () => {
                            gsap.set(this.backgroundMedia.$el, { clearProps: 'display' })
                        }
                    },
                    0
                )
            }
        }

        if (this.transitionIsVisible) this.timeline.progress(1)
    }

    afterLeave() {
        this.hasTransition = false

        if (this.hiddenHandle) {
            this.hiddenHandle.disengage()
            this.hiddenHandle = null
        }
    }

    close() {
        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.NAV_IS_OPEN, false)
    }

    updatePath() {
        const pathElements = this.$route.path.split('/')
        pathElements.pop()

        const path = pathElements.join('/')

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.NAV_PATH, path)
    }

    @Watch('isOpen')
    onIsOpenChange() {
        if (this.isOpen) {
            this.updatePath()
            this.enter()
        } else {
            this.leave()
        }
    }

    @Watch('$route')
    onRouteChange() {
        if (this.navigationIsAllowed) this.close()
    }

    @Watch('transitionIsVisible')
    onTransitionIsVisibleChange() {
        if (this.transitionIsVisible) this.close()
    }

    @Watch('hasPresentingLeader')
    onHasPresentingLeaderChange() {
        if (!this.isLeader && !this.hasPresentingLeader && !this.displayNav) {
            this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.NAV_IS_OPEN, false)
        }
    }

    onKeyUp(event: KeyboardEvent) {
        if (event.key === 'Escape' || event.key === 'Esc') {
            if (this.navigationIsAllowed) this.close()
        }
    }
}
