import Vue, { VueConstructor } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import 'focus-visible'
import 'vue-class-component/hooks'
import * as Sentry from '@sentry/browser'
import { applyPolyfills, defineCustomElements } from '@rezo-zero/intervention-request/loader'
// import '@/filters/romanize'
import VTooltip from 'v-tooltip'
import { VuePlausible } from 'vue-plausible'
import { version as packageVersion } from '../package.json'

const resourceUrl = process.env.VUE_APP_API_URL || ''

console.debug('📦 Version: ', packageVersion)

/*
 * Update JWT if service-worker is already installed
 * and WAIT for service worker to update JWT before init VueJS app.
 */
const serviceWorkerReady = new Promise(resolve => {
    const serviceWorker: ServiceWorker | null = navigator.serviceWorker?.controller
    if (serviceWorker) {
        const parsedUrl = new URL(window.location.href)
        const tokenParam = parsedUrl.searchParams.get('token')
        if (tokenParam) {
            console.debug('📤 Post new JWT to SW')
            serviceWorker.postMessage({
                type: 'SendJWT',
                data: {
                    jwt: tokenParam
                }
            })
        }
        /*
         * Listen to messages from SW to display error screen if necessary
         */
        navigator.serviceWorker.addEventListener('message', event => {
            if (event.data && event.data.type && event.data.type === 'NewJWTRegistered') {
                console.debug('📤 New JWT has been registered')
                resolve()
            }
        })
    } else {
        resolve()
    }
})

Vue.config.productionTip = false
Vue.config.ignoredElements = ['intervention-request', /intervention-\request-\w*/]
Vue.prototype.$resourceUrl = resourceUrl

Vue.use(VTooltip)

// Plausible integration
if (
    process.env.VUE_APP_PLAUSIBLE_API_HOST &&
    process.env.VUE_APP_PLAUSIBLE_DOMAIN &&
    process.env.VUE_APP_PLAUSIBLE_API_HOST !== '' &&
    process.env.VUE_APP_PLAUSIBLE_DOMAIN !== ''
) {
    Vue.use(VuePlausible, {
        domain: process.env.VUE_APP_PLAUSIBLE_DOMAIN,
        trackLocalhost: Boolean(process.env.VUE_APP_PLAUSIBLE_TRACK_LOCALHOST),
        hashMode: false,
        apiHost: process.env.VUE_APP_PLAUSIBLE_API_HOST
    })
    Vue.$plausible.enableAutoPageviews()
}

// Intervention Request
window.interventionRequestJS = {
    // debug: process.env.NODE_ENV === 'development',
    loading: 'lazy',
    strategy: 'default',
    strategies: {
        // @ts-ignore
        default: {
            baseUrl: resourceUrl + '/assets'
        }
    },
    mediaOptions: {
        quality: 80,
        progressive: 0
    }
}

applyPolyfills().then(() => {
    defineCustomElements().catch(error => console.debug(error))
})

if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'
}

if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        release: 'discover.rezo-zero.dev@' + packageVersion
    })
}

Promise.race([
    serviceWorkerReady,
    new Promise(resolve =>
        window.setTimeout(() => {
            console.warn('⌛ Service worker did not respond in time.')
            resolve()
        }, 5000)
    )
]).then(() => {
    new Vue({
        router,
        store,
        i18n,
        render: h => h(App as VueConstructor)
    }).$mount('#app')
})
