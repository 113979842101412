export default function loadImage(image: HTMLImageElement): Promise<HTMLImageElement> {
    return new Promise(function(resolve, reject) {
        if (image.complete) {
            resolve(image)
            return
        }

        function onLoaded() {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            removeListeners()
            resolve(image)
        }

        function onError() {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            removeListeners()
            reject(image)
        }

        function removeListeners() {
            image.removeEventListener('load', onLoaded)
            image.removeEventListener('error', onError)
        }

        image.addEventListener('load', onLoaded)
        image.addEventListener('error', onError)
    })
}
