






import Component from 'vue-class-component'
import { Prop, Ref, Vue } from 'vue-property-decorator'
import gsap from 'gsap'
import Color from '@/constants/color'

@Component
export default class ProgressBar extends Vue {
    @Prop({ default: Color.DARK }) color!: Color

    @Ref() progress!: HTMLElement

    get classNames() {
        return [this.$style.root, { [this.$style.rootLight]: this.color === Color.LIGHT }]
    }

    reset() {
        gsap.set(this.progress, { scaleX: 0 })
    }

    appear() {
        gsap.fromTo(
            this.progress,
            {
                scaleX: 0
            },
            {
                scaleX: 0.8,
                duration: 10,
                ease: 'none'
            }
        )
    }

    leave() {
        const scaleX = this.progress._gsap?.scaleX
        const duration = (scaleX ? 1 - scaleX : 1) * 1.2

        gsap.killTweensOf(this.progress)

        return gsap.to(this.progress, {
            scaleX: 1,
            duration,
            ease: 'power3.inOut'
        })
    }
}
