



import { Component, Prop, Ref } from 'vue-property-decorator'
import Vue from 'vue'
import gsap from 'gsap'
import loadBinpack from '@/utils/load-binpack'
import { getResourceUrl } from '@/utils/get-resource-url'

@Component
export default class Binpack360Content extends Vue {
    @Prop() media!: RoadizDocument
    @Prop({ default: true }) autoplay!: boolean

    @Ref('canvas') canvas!: HTMLCanvasElement

    currentImageIndex = 0
    imageCount = 0
    bitmaps: ImageBitmap[] | HTMLImageElement[] = []
    timeline!: GSAPTimeline
    context: CanvasRenderingContext2D | null = null

    mounted() {
        if (this.media && this.media.thumbnail && this.media.thumbnail.url && this.media.thumbnail.processable) {
            const thumbnailUrl = getResourceUrl(this.media.thumbnail.url)
            if (thumbnailUrl) {
                const thumbnailImg = document.createElement('img')
                thumbnailImg.onload = () => {
                    this.imageCount = 1
                    this.bitmaps = [thumbnailImg]
                    this.setCurrentImageFromRatio(0)
                    if (this.canvas) {
                        const context = this.canvas.getContext('2d')
                        this.canvas.width = thumbnailImg.width
                        this.canvas.height = thumbnailImg.height
                        if (context) {
                            this.context = context
                            this.draw()
                        }
                    }
                }
                thumbnailImg.src = thumbnailUrl
            }
        }
    }

    load(): void {
        if (
            this.media &&
            this.media.url &&
            this.media.mimeType === 'application/octet-stream' &&
            this.media.relativePath &&
            this.media.relativePath.endsWith('.binpack')
        ) {
            const url = getResourceUrl(this.media.url)
            if (url) {
                console.debug(`📥 ${this.media.url}`)
                loadBinpack(url).then(bitmaps => {
                    console.debug(`✅ ${this.media.url}`)
                    this.imageCount = bitmaps.length
                    this.bitmaps = bitmaps

                    // Start displaying middle image
                    this.setCurrentImageFromRatio(0.5)

                    if (this.autoplay) {
                        this.startBackAndForward()
                    }

                    if (this.canvas) {
                        const context = this.canvas.getContext('2d')

                        this.canvas.width = bitmaps[this.currentImageIndex].width
                        this.canvas.height = bitmaps[this.currentImageIndex].height

                        if (context) {
                            this.context = context

                            this.draw()
                        }
                    }
                })
            } else {
                throw new Error('Binpack URL is not valid')
            }
        } else {
            throw new Error('Media is not a valid .binpack resource')
        }
    }

    ready(): boolean {
        return (this.context && this.canvas && this.imageCount > 0) as boolean
    }

    draw(): void {
        // Eslint is dumb: does not eval ready method
        if (this.ready() && this.context) {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
            this.context.drawImage(this.bitmaps[this.currentImageIndex], 0, 0)
        }
    }

    /**
     * Set 360 image canvas state from 0 to 1.
     *
     * @param ratio
     */
    setCurrentImageFromRatio(ratio: number): void {
        if (ratio > 1.0 || ratio < 0.0) {
            throw new Error('Invalid ratio: ratio must be between 0 and 1.')
        }
        if (this.ready()) {
            this.currentImageIndex = Math.round((this.imageCount - 1) * ratio)
            if (this.bitmaps[this.currentImageIndex]) this.draw()
        }
    }

    play(): void {
        if (this.timeline) {
            this.timeline.play()
        }
    }

    pause(): void {
        if (this.timeline) {
            this.timeline.pause()
        }
    }

    startBackAndForward(): void {
        const anim = { ratio: 0.0 }

        this.timeline = gsap.timeline({ repeat: -1, yoyo: true })
        this.timeline.to(anim, {
            ratio: 1.0,
            onUpdate: () => {
                requestAnimationFrame(() => this.setCurrentImageFromRatio(anim.ratio))
            },
            ease: 'power1.inOut',
            duration: 4
        })
    }
}
