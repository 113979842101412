
































import { Component, Vue } from 'vue-property-decorator'
import { getAPILocale, getAPIToken } from '@/api'
import { getJWTPayload } from '@/utils/jwt'

@Component({
    components: {}
})
export default class JwtInfo extends Vue {
    animationIntersectionObserver: IntersectionObserver | null = null
    token: PresentationJWTPayload | null = getAPIToken() ? getJWTPayload(getAPIToken()) : null
    formatted: string | null = null
    expired = false
    weeks = 0
    days = 0
    hours = 0
    minutes = 0
    seconds = 0
    interval: NodeJS.Timeout | null = null
    intervals = {
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24,
        weeks: 1000 * 60 * 60 * 24 * 7
    }
    longLocales: { [locale: string]: string } = {
        en: 'en-US',
        fr: 'fr-CH'
    }

    mounted() {
        this.interval = setInterval(() => {
            this.updateDiffs()
        }, 1000)

        this.updateDiffs()

        if (this.token) {
            const expiry = new Date(this.token.exp * 1000)
            this.formatted = expiry.toLocaleDateString(this.longLocales[getAPILocale()] || 'en-US', {
                // weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            })
        }
    }

    destroyed() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    updateDiffs() {
        if (!this.token) return

        let diff = this.token.exp * 1000 - Date.now()
        if (diff > 0) {
            this.expired = false
            this.weeks = Math.floor(diff / this.intervals.weeks)
            diff -= this.weeks * this.intervals.weeks
            this.days = Math.floor(diff / this.intervals.day)
            diff -= this.days * this.intervals.day
            this.hours = Math.floor(diff / this.intervals.hour)
            diff -= this.hours * this.intervals.hour
            this.minutes = Math.floor(diff / this.intervals.minute)
            diff -= this.minutes * this.intervals.minute
            this.seconds = Math.floor(diff / this.intervals.second)
        } else {
            this.expired = true
        }
    }
}
