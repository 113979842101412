













import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component
export default class InputSwitch extends Vue {
    @Prop({ default: false }) checked!: boolean
    @Prop({ default: false }) disabled!: boolean

    internalChecked = this.checked

    @Watch('internalChecked')
    onInternalCheckedChange() {
        this.$emit('input', this.internalChecked)
    }

    @Watch('checked')
    onCheckedChange() {
        this.internalChecked = this.checked
    }
}
