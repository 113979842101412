import { MutationTree } from 'vuex'
import { RemoteSlide, RemoteState } from '@/@types/store'
import MutationRemoteType from '@/constants/mutation-remote-type'
import { Location as RouterLocation } from 'vue-router'

const mutations: MutationTree<RemoteState> = {
    [MutationRemoteType.LOCATION]: (state, value: RouterLocation) => (state.location = value),
    [MutationRemoteType.PRESENTING_LOCATION]: (state, value: RouterLocation) => (state.presentingLocation = value),
    [MutationRemoteType.POPIN_DOCUMENT]: (state, value: RoadizDocument) => (state.popinDocument = value),
    [MutationRemoteType.VIDEO_IS_PLAYING]: (state, value: boolean) => (state.videoIsPlaying = value),
    [MutationRemoteType.VIDEO_PAUSED]: (state, value: boolean) => (state.videoPaused = value),
    [MutationRemoteType.VIDEO_CURRENT_TIME]: (state, value: number) => (state.videoCurrentTime = value),
    [MutationRemoteType.SLIDES]: (state, value: Array<RemoteSlide>) => (state.slides = value),
    [MutationRemoteType.SINGLE_SLIDE]: (state, value: RemoteSlide) => {
        const index = state.slides.findIndex(slide => slide.id === value.id)
        const slide = index !== -1 ? Object.assign({}, state.slides[index], value) : value

        state.slides.splice(index === -1 ? state.slides.length : index, 1, slide)
    },
    [MutationRemoteType.NAV_IS_OPEN]: (state, value: boolean) => (state.navIsOpen = value),
    [MutationRemoteType.DISPLAY_NAV]: (state, value: boolean) => (state.displayNav = value),
    [MutationRemoteType.NAV_PATH]: (state, value: string) => (state.navPath = value),
    [MutationRemoteType.SAMPLE_VIEWER_CONTROLS_ALLOWED]: (state, value: boolean) =>
        (state.sampleViewerControlsAllowed = value),
    [MutationRemoteType.SECTION_PROGRESSES]: (state, value: Record<string, number>) =>
        (state.sectionProgresses = value),
    [MutationRemoteType.SINGLE_SECTION_PROGRESS]: (state, value: Record<string, number>) => {
        state.sectionProgresses = Object.assign({}, state.sectionProgresses, value)
    }
}

export default mutations
