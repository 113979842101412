









import Component from 'vue-class-component'
import Vue from 'vue'
import { Watch } from 'vue-property-decorator'
import { Getter, namespace, State } from 'vuex-class'
import TheNav from '@/components/TheNav.vue'
import TheNavBar from '@/components/TheNavBar.vue'
import PopinManager from '@/components/PopinManager.vue'
import { addRoutes } from '@/router'
import VueRouter, { Location as RouterLocation, Route } from 'vue-router'
import MutationRemoteType from '@/constants/mutation-remote-type'
import MutationType from '@/constants/mutation-type'
import ModuleNamespace from '@/constants/module-namespace'
import createRoutes from '@/router/init-routes'
import { RawLocation } from 'vue-router/types/router'

const { isNavigationFailure, NavigationFailureType } = VueRouter

@Component({
    components: {
        PopinManager,
        TheNav,
        TheNavBar
    }
})
export default class ThePresentation extends Vue {
    @State('presentationWalker') walker!: PresentationWalker
    @State isLeader!: boolean
    @State hasPresentingLeader!: boolean
    @State isPresentingLeader!: boolean
    @(namespace('remote').State('location')) remoteLocation!: string

    @Getter hasNavigation!: boolean

    assetsLoaded = false

    get routerViewKey(): string {
        return this.$route.meta?.parent
            ? this.$route.path
                  .split('/')
                  .slice(0, -1)
                  .join('/')
            : this.$route.path
    }

    mounted() {
        if (this.hasPresentingLeader && this.remoteLocation) this.navigateTo(this.remoteLocation)
    }

    navigateTo(location: RawLocation) {
        this.$router.push(location).catch(failure => {
            if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
                return
            }
        })
    }

    updatePresentingLocation() {
        if (!this.isPresentingLeader) return

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.PRESENTING_LOCATION, this.getLocation())
    }

    getLocation(): RouterLocation {
        return {
            path: this.$route.path,
            hash: this.$route.hash
        }
    }

    onRouterViewAssetsLoaded() {
        this.assetsLoaded = true
    }

    @Watch('$route')
    onRouteChange(route: Route, previousRoute: Route) {
        if (!this.isLeader) return

        if (route.path !== previousRoute.path) {
            this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.SLIDES, [])
        }

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.LOCATION, this.getLocation())

        this.updatePresentingLocation()
    }

    @Watch('walker', { immediate: true })
    onWalkerChange() {
        const routes = createRoutes(this.walker)

        this.$store.commit(MutationType.ROUTES, routes)

        addRoutes(routes)
    }

    @Watch('remoteLocation')
    onRemoteLocationChange() {
        this.navigateTo(this.remoteLocation)
    }

    @Watch('isPresentingLeader')
    onIsPresentingLeaderChange() {
        this.updatePresentingLocation()
    }
}
