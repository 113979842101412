

















import { Component, Prop, Vue } from 'vue-property-decorator'
import Markdown from './Markdown.vue'
import ArrowDownNextSlide from './ArrowDownNextSlide.vue'
import Color from '../constants/color'

@Component({
    components: {
        Markdown,
        ArrowDownNextSlide
    }
})
export default class KeyFigures extends Vue {
    @Prop({ default: null }) content!: string
    @Prop({ default: false }) invertContrast!: boolean

    animationIntersectionObserver: IntersectionObserver | null = null
    color = this.invertContrast ? Color.DARK : Color.LIGHT
    contentIsLarge = false
    contentIsVeryLarge = false

    $refs!: {
        figures: Markdown
    }

    get classNames() {
        const classNames = [this.$style.root, 'color--' + this.color]

        if (this.invertContrast) {
            classNames.push(this.$style['root--inverted-contrast'])
        }

        if (this.$slots.default) {
            classNames.push(this.$style['root--has-slot'])
        }

        return classNames
    }

    mounted() {
        this.animationIntersectionObserver = new IntersectionObserver(this.onKeyFigureObserverChange, {
            rootMargin: `20px`,
            threshold: [0.5, 1]
        })
        this.animationIntersectionObserver.observe(this.$el)

        this.$nextTick(() => {
            this.checkContentSize()
        })
    }

    beforeDestroy() {
        this.animationIntersectionObserver?.disconnect()
    }

    checkContentSize() {
        if (!this.content) return

        const parsedContent = this.$refs.figures.parsedContent
        const textContent = parsedContent.replace(/<[^>]*>/g, '')

        this.contentIsLarge = textContent.length > 7
        this.contentIsVeryLarge = textContent.length > 14
    }

    onKeyFigureObserverChange(entries: IntersectionObserverEntry[]) {
        const figures = this.$refs['figures'] as Markdown

        if (!figures) return

        if (entries[0].intersectionRatio >= 0.5 && figures.$el.classList.contains('text-number--hidden')) {
            figures.$el.classList.remove('text-number--hidden')
        }
    }
}
