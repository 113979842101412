const baseUrl: string = process.env.VUE_APP_API_URL || ''
const rootUri: string = process.env.VUE_APP_API_ROOT_URI || ''

const cache: Record<string, Response> = {}
const etagRecords: Record<string, string> = {}

let token: string | undefined
let locale = 'en'

export function setAPIToken(value: string) {
    token = value
}

export function getAPIToken(): string | null {
    return token || null
}

export function setAPILocale(value: string) {
    locale = value
}

export function getAPILocale(): string {
    return locale || 'en'
}

export function call(endpoint: string, init?: RequestInit): Promise<Response> {
    if (!baseUrl) {
        throw new Error('Please set the base url before calling the API')
    }
    if (!token) {
        throw new Error('Please set the token before calling the API')
    }
    if (!locale) {
        throw new Error('Please set the locale before calling the API')
    }

    const url = `${baseUrl + endpoint}?_locale=${locale}`

    if (init?.cache !== 'no-cache' && cache[url]?.status === 200) {
        return Promise.resolve(cache[url].clone())
    }

    // headers
    const headers = new Headers()

    headers.append('Accept', 'application/json')
    headers.append('Authorization', `Bearer ${token}`)
    /*
     * Send first ancestor origin to server to let it decide
     * if origin is allowed.
     * Firefox does not support it.
     */
    if (location.ancestorOrigins && location.ancestorOrigins.length > 0) {
        headers.append('X-Ancestor-Origin', location.ancestorOrigins.item(0) ?? '-')
    }

    if (etagRecords[url] && init?.cache === 'no-cache') {
        headers.append('If-None-Match', etagRecords[url])
    }

    return fetch(url, {
        ...init,
        method: 'GET',
        redirect: 'follow',
        cache: 'no-cache',
        headers
    }).then(response => {
        const etag = response.headers.get('etag')

        if (etag) etagRecords[url] = etag.replace('W/', '')

        cache[url] = response

        return response.clone()
    })
}

export function getPresentationBySlug(slug: string, init?: RequestInit): Promise<Response> {
    return call(`${rootUri}/presentation/by-slug/${slug}`, init)
}

export function getSlideById(id: string, init?: RequestInit): Promise<Response> {
    return call(id, init)
}
