




































































import { Component, Prop, Ref } from 'vue-property-decorator'
import Vue from 'vue'
import { getResourceUrl } from '@/utils/get-resource-url'
import LottieAnimation from '@/components/LottieAnimation.vue'
import Binpack360Content from '@/components/Binpack360Content.vue'
import BaseImage from '@/components/BaseImage.vue'
import { State } from 'vuex-class'
// import loadImage from '@/utils/load-image'

/**
 * @see https://screensiz.es/monitor
 */
const FULLSCREEN_FORMATS = {
    media: [
        {
            srcset: [
                {
                    format: {
                        width: 2560,
                        quality: 90
                    }
                }
            ],
            rule: '(min-width: 1921px) and (max-width: 2560px)'
        },
        {
            srcset: [
                {
                    format: {
                        width: 1920,
                        quality: 90
                    }
                }
            ],
            rule: '(min-width: 1441px) and (max-width: 1920px)'
        },
        {
            srcset: [
                {
                    format: {
                        width: 1440,
                        quality: 90
                    }
                }
            ],
            rule: '(min-width: 1367px) and (max-width: 1440px)'
        },
        {
            srcset: [
                {
                    format: {
                        width: 1366,
                        quality: 90
                    }
                }
            ],
            rule: '(min-width: 1025px) and (max-width: 1366px)'
        },
        {
            srcset: [
                {
                    format: {
                        width: 1024,
                        quality: 90
                    }
                }
            ],
            rule: '(max-width: 1024px)'
        }
    ]
}
@Component({
    components: { LottieAnimation, Binpack360Content, BaseImage }
})
export default class MediaContent extends Vue {
    @Prop() media!: RoadizDocument
    @Prop({ default: false, type: Boolean }) fullscreen!: boolean
    @Prop({ default: false, type: Boolean }) lottieLoop!: boolean
    @Prop({ default: true, type: Boolean }) useThumbnail!: boolean
    @Prop({ default: true, type: Boolean }) autoplay!: boolean
    @Prop({ default: false, type: Boolean }) background!: boolean
    @Prop({ default: false, type: Boolean }) cover!: boolean
    @Prop({ default: false }) formats!: string | boolean
    @Prop({ default: 'lazy', type: String }) loading!: 'lazy' | 'auto' | 'eager'

    @State windowWidth!: number

    @Ref('lottie') lottie?: LottieAnimation
    @Ref('nativeVideo') nativeVideo?: HTMLVideoElement
    @Ref('externalVideo') externalVideo?: HTMLIFrameElement
    @Ref('binpack360Content') binpack360Content?: Binpack360Content
    @Ref() imageWrapper!: HTMLElement

    loaded = false
    isMounted = false

    get classNames() {
        return [
            this.$style.root,
            this.fullscreen && this.$style.rootFullscreen,
            this.cover && this.$style.rootIsCover,
            this.background && this.$style.rootIsBackground
        ]
    }

    get iframeStyle() {
        const style: Record<string, string> = {}

        if (this.cover && this.windowWidth && this.isMounted && this.media.imageHeight && this.media.imageWidth) {
            const containerWidth = (this.$el as HTMLElement).offsetWidth
            const containerHeight = (this.$el as HTMLElement).offsetHeight

            const mediaWidth = parseInt(this.media.imageWidth)
            const mediaHeight = parseInt(this.media.imageHeight)

            const scaleX = containerWidth / mediaWidth
            const scaleY = containerHeight / mediaHeight
            const scale = Math.max(scaleX, scaleY)

            const width = Math.round(mediaWidth * scale)
            const height = Math.round(mediaHeight * scale)

            style.width = width.toString() + 'px'
            style.height = height.toString() + 'px'
        }

        return style
    }

    get imageFormats(): string | boolean {
        return this.formats || (this.fullscreen ? JSON.stringify(FULLSCREEN_FORMATS) : false)
    }

    get mediaSrc() {
        return getResourceUrl(this.media.url) || ''
    }

    get iframeSrc() {
        return `https://player.vimeo.com/video/${this.media.embedId}?background=1&autoplay=${
            this.autoplay ? 1 : 0
        }&loop=1&byline=0&title=0`
    }

    get isEmbed() {
        return this.media.embedId
    }

    get isBinpack() {
        return this.media.mimeType === 'application/octet-stream' && this.media.relativePath?.endsWith('.binpack')
    }

    get isLottie() {
        return this.media.mimeType === 'application/json'
    }

    get isVideo() {
        return this.media.mimeType === 'video/mp4'
    }

    get isSvg() {
        return this.media.mimeType?.includes('image/svg')
    }

    get isImage() {
        return (
            !this.isEmbed &&
            (this.media.mimeType === 'image/jpeg' ||
                this.media.mimeType === 'image/png' ||
                this.media.mimeType === 'image/webp' ||
                this.media.mimeType === 'image/avif' ||
                this.media.mimeType === 'image/gif')
        )
    }

    mounted() {
        this.isMounted = true
    }

    load(): void {
        if (this.isBinpack) {
            this.binpack360Content?.load()
        }
    }

    play() {
        this.lottie?.play()
        this.nativeVideo?.play()
        this.binpack360Content?.play()

        if (this.externalVideo && this.externalVideo.contentWindow) {
            this.externalVideo.contentWindow.postMessage('{"method":"play"}', '*')
        }
    }

    pause() {
        this.lottie?.stop()

        if (this.$refs.nativeVideo && this.$refs.nativeVideo instanceof HTMLVideoElement) {
            this.$refs.nativeVideo.pause()
        }

        this.binpack360Content?.pause()

        if (this.externalVideo && this.externalVideo.contentWindow) {
            this.externalVideo.contentWindow.postMessage('{"method":"pause"}', '*')
        }
    }

    stop() {
        this.pause()
    }

    onLoaded() {
        this.loaded = true
    }
}
