interface ServiceWorkerMessage {
    type: string
    data: object | string | number | null
}

const messageStack: Array<ServiceWorkerMessage> = []

export function postMessage(message: ServiceWorkerMessage, active?: ServiceWorker | null) {
    if (process.env.VUE_APP_USE_SW === '1') {
        const serviceWorker: ServiceWorker | null = active || navigator.serviceWorker?.controller
        if (serviceWorker) {
            console.debug('📤 Post message to SW', message)
            serviceWorker.postMessage(message)
        } else {
            console.warn('Service worker is still not ready')
            messageStack.push(message)
        }
    }
}

export function sendStackedMessages(registration: ServiceWorkerRegistration) {
    messageStack.forEach((message, index, messages) => {
        if (registration.active) {
            console.debug('📤 Post message to SW', message)
            registration.active.postMessage(message)
            messages.slice(index, 1)
        }
    })
}
