import { ActionContext, ActionTree } from 'vuex'
import { RemoteState, RootState } from '@/@types/store'
import { getSocket } from '@/websocket'
import EventType from '@/constants/event-type'
import MutationType from '@/constants/mutation-type'

const actions: ActionTree<RemoteState, RootState> = {
    startPresenting: ({ commit, state }: ActionContext<RemoteState, RootState>) => {
        return new Promise((resolve, reject) => {
            const socket = getSocket()

            if (!socket) {
                commit(MutationType.IS_PRESENTING_LEADER, false, { root: true })

                return reject()
            }

            const stateCopy = JSON.parse(JSON.stringify(state))

            socket.emit(EventType.START_PRESENTING, stateCopy, (isPresenting: boolean) => {
                commit(MutationType.IS_PRESENTING_LEADER, isPresenting, { root: true })

                return isPresenting ? resolve() : reject()
            })
        })
    },
    stopPresenting: ({ commit }: ActionContext<RemoteState, RootState>) => {
        const socket = getSocket()

        if (!socket) return

        commit(MutationType.IS_PRESENTING_LEADER, false, { root: true })

        socket.emit(EventType.STOP_PRESENTING)
    }
}

export default actions
