






















import Component from 'vue-class-component'
import { Ref, Vue, Watch } from 'vue-property-decorator'
import TheLogo from '@/components/TheLogo.vue'
import TheNavToggle from '@/components/TheNavToggle.vue'
import { Getter, namespace, State } from 'vuex-class'
import NavContextualButton from '@/components/NavContextualButton.vue'
import gsap from 'gsap'
import { RouteConfig } from 'vue-router'
import ModuleNamespace from '@/constants/module-namespace'
import TheUserMenu from '@/components/TheUserMenu.vue'
import ButtonIcon from '@/components/ButtonIcon.vue'
import IconInfo from '@/assets/img/icons/i.svg?sprite'
import eventBus from '@/utils/event-bus'
import EventType from '@/constants/event-type'
import SlideType from '@/constants/slide-type'

const remoteModule = namespace(ModuleNamespace.REMOTE)

@Component({
    components: {
        ButtonIcon,
        TheUserMenu,
        TheLogo,
        TheNavToggle,
        NavContextualButton,
        IconInfo
    }
})
export default class TheNavBar extends Vue {
    @State numberSlides!: number
    @State slideIndex!: number
    @State presentationIsVisible!: boolean
    @State navRoutes!: Array<RouteConfig>
    @State isLeader!: boolean
    @State uiColor!: boolean
    @remoteModule.State navIsOpen!: boolean
    @remoteModule.State sampleViewerControlsAllowed!: boolean

    @Getter hasNavigation!: boolean
    @Getter navigationIsAllowed!: boolean
    @Getter hasContextualNavigation!: boolean
    @Getter getSectionIndexByPath!: Function

    @Ref() previousSlide!: NavContextualButton | undefined
    @Ref() nextSlide!: NavContextualButton | undefined

    get contextualNavigationIsVisible() {
        return !this.navIsOpen
    }

    get currentSlideHasTour(): boolean {
        return this.$store.state.slideType === SlideType.SAMPLE_VIEWER && this.$store.getters.displaySampleViewerTour
    }

    enter() {
        this.showContextualNavigation({ delay: 0.9 })
    }

    showContextualNavigation(animOptions: GSAPTweenVars = {}) {
        if (!this.hasContextualNavigation || !this.nextSlide || !this.previousSlide) return

        const offset = this.hasNavigation ? 60 : 20
        const toVars = {
            y: 0,
            opacity: 1,
            ease: 'power3.out',
            duration: 0.8
        }

        gsap.fromTo(
            [this.previousSlide.$el, this.nextSlide.$el],
            {
                y: gsap.utils.wrap([offset, -offset]),
                opacity: 0
            },
            { ...toVars, ...animOptions }
        )
    }

    hideContextualNavigation(animOptions: GSAPTweenVars = {}) {
        if (!this.hasContextualNavigation || !this.nextSlide || !this.previousSlide) return

        const offset = this.hasNavigation ? 60 : 20
        const toVars = {
            y: gsap.utils.wrap([offset, -offset]),
            opacity: 0,
            ease: 'power3.in',
            duration: 0.6
        }

        gsap.to([this.previousSlide.$el, this.nextSlide.$el], { ...toVars, ...animOptions })
    }

    @Watch('presentationIsVisible')
    onPresentationIsVisibleChange() {
        if (this.presentationIsVisible) this.enter()
    }

    @Watch('contextualNavigationIsVisible')
    onContextualNavigationIsVisibleChange() {
        if (this.contextualNavigationIsVisible) this.showContextualNavigation()
        else this.hideContextualNavigation()
    }

    onTourClick() {
        eventBus.$emit(EventType.START_TOUR)
    }
}
