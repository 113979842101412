import HttpError from '@/errors/HttpError'

export default async function createResponseError(response: Response): Promise<HttpError> {
    const body = await response.json()
    switch (response.status) {
        case 404:
            return new HttpError('Not found', 404, body.message)

        case 401:
            return new HttpError('Token is missing or invalid', 401, body.message)

        case 403:
            return new HttpError('Access denied', 403, body.message)

        case 500:
            return new HttpError('Server error', 500, body.message)

        case 503:
            return new HttpError('Server unavailable', 503, body.message)
    }

    return new HttpError('An error occurred', 500, body.message)
}
