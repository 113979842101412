import { io } from 'socket.io-client'
import { Socket } from 'socket.io-client'
import { ManagerOptions } from 'socket.io-client/build/manager'
import { SocketOptions } from 'socket.io-client/build/socket'

let socket: Socket

function createSocket(): Socket {
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')

    return io(process.env.VUE_APP_WEBSOCKET_URL || window.location.origin, {
        path: process.env.VUE_APP_WEBSOCKET_PATH,
        query: { token }
    } as Partial<ManagerOptions & SocketOptions>)
}

export function socketEnabled() {
    return process.env.VUE_APP_USE_WEBSOCKET === '1'
}

export function getSocket(): Socket | undefined {
    if (!socketEnabled()) return

    if (!socket) socket = createSocket()

    return socket
}
