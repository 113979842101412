











import Component from 'vue-class-component'
import Vue from 'vue'
import ErrorScreen from '@/components/ErrorScreen.vue'
import ThePresentation from '@/components/ThePresentation.vue'
import { getPresentationBySlug, setAPILocale, setAPIToken } from '@/api'
import createResponseError from '@/errors/create-response-error'
import SplashScreen from '@/components/SplashScreen.vue'
import '@/scss/main.scss'
import HttpError from '@/errors/HttpError'
import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/types'
import EventType from '@/constants/event-type'
import eventBus from '@/utils/event-bus'
import { throttle } from 'throttle-debounce'
import TransitionScreen from '@/components/TransitionScreen.vue'
import { setSentryContext, setSentryLocale } from '@/utils/sentry'
import { getJWTPayload } from '@/utils/jwt'
import { mediaIsMin } from '@/utils/media'
import MutationType from '@/constants/mutation-type'
import { State } from 'vuex-class'
import DataObserver from '@/utils/data-observer'

@Component({
    components: {
        ThePresentation,
        SplashScreen,
        ErrorScreen,
        TransitionScreen
    }
})
export default class App extends Vue {
    @State slug!: string
    @State presentationWalker!: PresentationWalker | null
    @State transitionIsVisible!: boolean

    error: Error | null = null
    isLoading = false
    skipSplash = process.env.VUE_APP_SKIP_SPLASH === '1'
    dataObserver = new DataObserver(this.fetchData.bind(this))

    resizeCallback!: EventListener

    get splashScreenIsVisible() {
        return this.isLoading && !this.skipSplash
    }

    get hasPresentation() {
        return !this.error && this.presentationWalker
    }

    mounted(): void {
        this.setIsSlideshow()

        /*
         * Prevent double embedding.
         * Firefox does not support location.ancestorOrigins
         */
        if (location.ancestorOrigins && location.ancestorOrigins.length > 1) {
            this.error = new HttpError('Access denied', 403, 'Presentation cannot be embedded twice or more')
            Sentry.captureMessage('Presentation cannot be embedded twice or more', {
                level: Severity.Warning
            })
            return
        }

        if (this.slug) {
            this.init()
            if (!this.error) this.load()
        } else {
            this.error = new HttpError('Not Found', 404)

            if (process.env.VUE_APP_SENTRY_DSN) {
                Sentry.captureMessage('No presentation slug found', {
                    level: Severity.Warning
                })
            }
        }

        this.$store.commit(MutationType.PRESENTATION_IS_VISIBLE, !this.splashScreenIsVisible)

        this.resizeCallback = throttle(200, this.onResize)
        window.addEventListener('resize', this.resizeCallback)

        this.setVhUnit()
        this.setWindowSize()

        if (!this.splashScreenIsVisible) this.dataObserver.observe()

        /*
         * Listen to messages from SW to display error screen if necessary
         */
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('message', event => {
                if (event.data && event.data instanceof Error) {
                    console.warn('📥 New error from SW', event.data)
                    this.error = event.data
                } else if (event.data && event.data.type && event.data.type === 'ReloadAfterWorkerUpdated') {
                    // Hard reload application if Service Worker updated and activated.
                    // console.debug('📥 SW has upgraded and activated… reload browser')
                    // window.location.reload()
                }
            })
        }
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.resizeCallback)

        this.dataObserver.disconnect()
    }

    init() {
        const parsedUrl = new URL(window.location.href)
        const token = parsedUrl.searchParams.get('token')
        const locale = parsedUrl.searchParams.get('_locale')

        let jwtPayload: PresentationJWTPayload | null = null

        try {
            if (token) jwtPayload = getJWTPayload<PresentationJWTPayload>(token)
        } catch (error) {
            this.error = error

            if (process.env.VUE_APP_SENTRY_DSN) {
                Sentry.captureException(this.error)
            }
        }

        if (token && jwtPayload) {
            setAPIToken(token)
            setSentryContext(jwtPayload)

            this.$store.commit(MutationType.IS_LEADER, jwtPayload.scopes.includes('presentation-leader'))
        }

        if (locale) {
            setAPILocale(locale)
            setSentryLocale(locale)
        }
    }

    async load() {
        this.isLoading = true

        await this.fetchData()

        if (this.hasPresentation) {
            await this.routerAfterEach()
            await this.presentationAssetsLoaded()
        }

        this.isLoading = false
    }

    async fetchData() {
        return getPresentationBySlug(this.slug, { cache: 'no-cache' })
            .then(async response => {
                if (response.status === 200) {
                    if (this.error) {
                        this.isLoading = true // display the loading screen
                        this.error = null

                        setTimeout(() => {
                            this.isLoading = false // the data are already loaded then we can skip the loading
                        })
                    }

                    return response.json().then(result => {
                        this.$store.commit(MutationType.PRESENTATION_WALKER, result)
                    })
                } else if (response.status === 304) {
                    // no change
                } else {
                    this.error = await createResponseError(response)
                    if (process.env.VUE_APP_SENTRY_DSN) {
                        Sentry.captureException(this.error)
                    }
                }
            })
            .catch(error => {
                this.error = error
                if (process.env.VUE_APP_SENTRY_DSN) {
                    Sentry.captureException(error)
                }
            })
    }

    async routerAfterEach(): Promise<void> {
        return new Promise(resolve => {
            this.$nextTick(() => {
                const unregister = this.$router.afterEach(function() {
                    unregister()
                    resolve()
                })
            })
        })
    }

    async presentationAssetsLoaded(): Promise<void> {
        return new Promise(resolve => {
            const presentation = this.$refs.presentation as ThePresentation

            if (presentation.assetsLoaded) {
                resolve()
                return
            }

            const unregister = presentation.$watch('assetsLoaded', () => {
                unregister()
                resolve()
            })
        })
    }

    setIsSlideshow() {
        this.$store.commit(MutationType.IS_SLIDESHOW, mediaIsMin('lg'))
    }

    setWindowSize() {
        this.$store.commit(MutationType.WINDOW_WIDTH, window.innerWidth)
    }

    setVhUnit() {
        // @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        // fix vh unit on mobile
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    }

    onSplashScreenLeave(element: HTMLElement, done: Function) {
        ;(this.$refs.splashScreen as SplashScreen).leave().then(() => {
            done()
        })
    }

    onSplashScreenAfterLeave() {
        this.dataObserver.observe()
    }

    onResize() {
        this.setVhUnit()
        this.setWindowSize()
        this.setIsSlideshow()

        eventBus.$emit(EventType.RESIZE)
    }
}
