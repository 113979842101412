

























import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import ProgressBar from '@/components/ProgressBar.vue'
import MainLogo from '@/components/MainLogo.vue'
import NameImage from '@/assets/img/name.svg?inline'
import gsap from 'gsap'
import MutationType from '@/constants/mutation-type'
import JwtInfo from '@/components/JwtInfo.vue'

@Component({
    components: {
        MainLogo,
        NameImage,
        ProgressBar,
        JwtInfo
    }
})
export default class SplashScreen extends Vue {
    @Prop() hasError!: boolean
    @Ref() progressBar!: ProgressBar

    mounted() {
        this.progressBar.appear()
    }

    leave() {
        const timeline = gsap.timeline()

        if (!this.hasError) {
            timeline
                .add(this.progressBar.leave())
                .to(this.progressBar.$el, {
                    opacity: 0,
                    ease: 'none',
                    duration: 0.4
                })
                .to(this.$refs.name as HTMLElement, {
                    opacity: 1,
                    ease: 'none',
                    duration: 0.7
                })
                .add(() => {
                    this.$store.commit(MutationType.PRESENTATION_IS_VISIBLE, true)
                })
                .addLabel('reveal')
                .to(
                    [this.$refs.outer, this.$refs.inner],
                    {
                        yPercent: gsap.utils.wrap([-100, 100]),
                        duration: 1,
                        ease: 'power2.inOut'
                    },
                    'reveal'
                )
                .to(
                    this.$refs.content as HTMLElement,
                    {
                        opacity: 0,
                        duration: 0.5,
                        ease: 'none'
                    },
                    'reveal'
                )
                .to(
                    this.$refs.background as HTMLElement,
                    {
                        opacity: 0,
                        duration: 0.9,
                        ease: 'none'
                    },
                    'reveal'
                )
        } else {
            timeline.to(
                this.$el,
                {
                    opacity: 0,
                    duration: 0.4
                },
                0
            )
        }

        return timeline
    }
}
