var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.$style.root, _vm.isOrdered && _vm.$style.rootIsOrdered, _vm.isLeader && _vm.$style.rootIsLeader],attrs:{"id":_vm.id,"aria-hidden":!_vm.isVisible}},[_c('ul',{class:_vm.$style.groupList},_vm._l((_vm.routeGroups),function(routeGroup,routeIndex){return _c('li',{key:routeGroup[0].path,ref:"group",refInFor:true,class:[
                _vm.$style.group,
                _vm.linkHoveredRouteIndex !== -1 && _vm.linkHoveredRouteIndex !== routeIndex && _vm.$style.groupBlur
            ]},[(_vm.routeGroups.length > 1)?_c('div',{class:_vm.$style.groupHead},[(routeGroup[0].meta.overTitle)?_c('div',{staticClass:"text-subtitle",class:_vm.$style.groupTitle},[_vm._v(" "+_vm._s(routeGroup[0].meta.overTitle)+" ")]):_vm._e()]):_vm._e(),_c('ul',{class:[_vm.$style.list, _vm.linkHoveredIndex !== -1 && _vm.$style.listHasHoveredLink]},_vm._l((routeGroup),function(route,index){return _c('router-link',{key:route.path,attrs:{"to":_vm.routePathsByPath[route.path],"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isExactActive = ref.isExactActive;
            var linkRoute = ref.route;
return [_c('li',{class:[
                            _vm.$style.item,
                            linkRoute.path === _vm.$route.path && _vm.$style.itemIsActive,
                            route.meta && route.meta.isShadowContent && _vm.$style.itemIsShadowContent,
                            _vm.linkHoveredIndex === index &&
                                _vm.linkHoveredRouteIndex === routeIndex &&
                                _vm.$style.itemLinkHovered
                        ]},[_c(_vm.routeHasChildrenCapability(route) ? 'button' : 'a',{tag:"component",class:_vm.$style.link,attrs:{"href":!_vm.routeHasChildrenCapability(route) && href,"aria-controls":_vm.routeHasChildrenCapability(route) && _vm.getItemId(route)},on:{"click":function($event){return _vm.onLinkClick($event, navigate, route)},"mouseenter":function($event){return _vm.onLinkMouseEnter(index, routeIndex)},"mouseleave":_vm.onLinkMouseLeave}},[_c('span',{class:_vm.$style.linkInner},[(_vm.isLeader)?_c('nav-link-status',_vm._b({attrs:{"ordered":_vm.isOrdered}},'nav-link-status',_vm.linkStatusByPath[route.path],false)):_vm._e(),_c('span',{class:_vm.$style.linkText},[_vm._v(_vm._s(route.meta.title))]),(route.children)?_c('span',{class:_vm.$style.linkIcon},[_c('chevron-right',{class:_vm.$style.linkIconChevron,attrs:{"width":"6","height":"10"}}),_c('span',{class:_vm.$style.linkIconLine})],1):_vm._e()],1)])],1)]}}],null,true)})}),1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }