













import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import IconArrowDown from '@/assets/img/icons/arrow-down.svg?sprite'
import EventType from '@/constants/event-type'
import eventBus from '@/utils/event-bus'
import Color from '@/constants/color'
import ButtonIcon from '@/components/ButtonIcon.vue'
import { State } from 'vuex-class'

@Component({
    components: {
        IconArrowDown,
        ButtonIcon
    }
})
export default class ArrowDownNextSlide extends Vue {
    @Prop({ default: Color.DARK }) color!: Color

    @State hasContextualNavigation!: boolean

    get invertedColor() {
        return this.color === Color.DARK ? Color.LIGHT : Color.DARK
    }

    onClick() {
        eventBus.$emit(EventType.GOTO_NEXT_SLIDE)
    }
}
