import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import * as Sentry from '@sentry/browser'
import store from '@/store'
import MutationType from '@/constants/mutation-type'

Vue.use(VueRouter)

const slug = window.location.pathname.split('/')[1]
const base = '/' + (slug ? slug + '/' : '')

const routes: Array<RouteConfig> = []

const createRouter = () =>
    new VueRouter({
        mode: 'history',
        base
    })
const router = createRouter()

if (slug) store.commit(MutationType.SLUG, slug)

// keep query params during navigation
router.beforeEach((to, from, next) => {
    const query = { ...from.query, ...to.query }
    const hasSameQueryParams = JSON.stringify(to.query) === JSON.stringify(query)

    if (!hasSameQueryParams) {
        const query = { ...from.query, ...to.query }

        next({ path: to.path, query, hash: to.hash })
    } else {
        next()
    }
})

// prevent a customer to access directly a shadow content route if it's not during a presentation
router.beforeEach((to, from, next) => {
    if (to.meta?.isShadowContent && !store.state.isLeader && !store.state.hasPresentingLeader) {
        next({ path: 'not-found', replace: true })
    } else {
        next()
    }
})

router.onError(function(error: Error) {
    console.debug(error.message)
    if (process.env.VUE_APP_SENTRY_DSN) {
        Sentry.captureException(error)
    }
    // Do not redirect to / there is not page at root
    // router.push({ path: '/' }).catch(error => {
    //     console.debug(error.message)
    //     if (process.env.VUE_APP_SENTRY_DSN) {
    //         Sentry.captureException(error)
    //     }
    // })
})

// @see https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
    const newRouter = createRouter()
    // @ts-ignore matcher is not into the official API
    router.matcher = newRouter.matcher
}

function addRoutes(value: Array<RouteConfig>) {
    const routes = router.getRoutes()

    if (routes.length) resetRouter()

    value.forEach(route => router.addRoute(route))
}

export default router
export { addRoutes, routes }
