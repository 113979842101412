import { RemoteState } from '@/@types/store'

const state: RemoteState = {
    location: null,
    presentingLocation: null,
    popinDocument: null,
    videoIsPlaying: false,
    videoPaused: false,
    videoCurrentTime: 0,
    slides: [],
    navIsOpen: false,
    navPath: '',
    displayNav: false,
    sampleViewerControlsAllowed: false,
    sectionProgresses: {}
}

export default state
