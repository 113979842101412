












import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import loadImage from '@/utils/load-image'

@Component
export default class BaseImage extends Vue {
    @Prop() src!: string
    @Prop() width!: number
    @Prop() height!: number
    @Prop() formats!: string
    @Prop({ default: '' }) alt!: number
    @Prop({ default: 'lazy' }) loading!: 'lazy' | 'eager' | 'auto'

    get classNames() {
        return [this.$style.root, this.loading === 'lazy' && this.$style.rootLazy]
    }

    load() {
        setTimeout(() => {
            setTimeout(() => {
                const image = this.$el.shadowRoot?.querySelector('img')

                if (image) return loadImage(image)
                else Promise.resolve()
            })
        })
    }
}
