export function resolveSlug(walker: SlideWalker | PresentationWalker): string {
    return walker.metadata?.realSlug || walker.item.slug
}

export function resolveType(walker: SlideWalker | PresentationWalker): string {
    return walker.metadata?.realType || walker.item['@type']
}

export function resolveId(walker: SlideWalker | PresentationWalker): string {
    return walker.metadata?.realId || walker.item['@id']
}

export function resolveTitle(walker: SlideWalker | PresentationWalker): string {
    return walker.metadata?.realTitle || walker.item.title
}

export function resolveShadowContent(walker: SlideWalker | PresentationWalker): boolean {
    return walker.metadata?.realShadowContent || walker.item.shadowContent || false
}
