/* eslint-disable no-console */

import { sendStackedMessages } from '@/utils/service-worker-message'

if (process.env.VUE_APP_USE_SW === '1') {
    /*
     * Need to pass necessary data before SW install,
     * use Query parameters…
     */
    const parsedUrl = new URL(window.location.href)
    const tokenParam = parsedUrl.searchParams.get('token')
    const localeParam = parsedUrl.searchParams.get('_locale')
    const base: string | undefined = parsedUrl.pathname
    const slug: string | null = base && base.length > 1 ? base.split('/')[1] : null
    const swUrl = `${process.env.BASE_URL}service-worker.js?token=${tokenParam}&_locale=${localeParam}&slug=${slug}`

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(swUrl).then(
            (registration: ServiceWorkerRegistration) => {
                // Registration was successful
                console.debug('👷 ServiceWorker registration successful')
                sendStackedMessages(registration)
            },
            err => {
                // Registration failed
                console.debug('👷 ServiceWorker registration failed: ', err)
            }
        )
    }
}
