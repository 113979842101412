












import Component from 'vue-class-component'
import { Vue, Watch } from 'vue-property-decorator'
import MainLogo from '@/components/MainLogo.vue'
import Color from '@/constants/color'
import { Getter, namespace, State } from 'vuex-class'
import gsap from 'gsap'
import ModuleNamespace from '@/constants/module-namespace'

const remoteModule = namespace(ModuleNamespace.REMOTE)

@Component({
    components: {
        MainLogo
    }
})
export default class TheLogo extends Vue {
    @State presentationIsVisible!: boolean
    @remoteModule.State navIsOpen!: boolean

    @Getter navigationIsAllowed!: boolean

    get isLight() {
        return this.$store.state.uiColor === Color.LIGHT && !this.navIsOpen
    }

    enter() {
        const rect = this.$el.getBoundingClientRect()

        gsap.from(this.$el, {
            y: -rect.bottom,
            ease: 'power3.out',
            duration: 1,
            delay: 0.6
        })
    }

    @Watch('presentationIsVisible')
    onPresentationIsVisibleChange() {
        if (this.presentationIsVisible) this.enter()
    }

    onClick(event: MouseEvent, navigate: Function) {
        if (this.navigationIsAllowed) navigate(event)
    }
}
