






import { Component, Vue } from 'vue-property-decorator'
import LogoImage from '@/assets/img/logo.svg?inline'

@Component({
    components: {
        LogoImage
    }
})
export default class MainLogo extends Vue {}
