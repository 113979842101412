import SlideType from '@/constants/slide-type'
import { Component, Dictionary } from 'vue-router/types/router'

const components: Dictionary<Component> = {
    [SlideType.BASIC_CAROUSEL]: () => import('./views/SlideBasicCarousel.vue'),
    [SlideType.BASIC_CAROUSEL_ITEM]: () => import('./views/SlideBasicCarouselItem.vue'),
    [SlideType.COLLECTIONS_CAROUSEL]: () => import('./views/SlideCollectionsCarousel.vue'),
    [SlideType.COLLECTION]: () => import('./views/SlideCollection.vue'),
    [SlideType.CONTACT]: () => import('./views/SlideContact.vue'),
    [SlideType.CONTAINER]: () => import('./views/SlideContainer.vue'),
    [SlideType.CUSTOM_ANIMATION]: () => import('./views/SlideCustomAnimation.vue'),
    [SlideType.DROPDOWN]: () => import('./views/SlideDropdown.vue'),
    [SlideType.FLOATING_IMAGES]: () => import('./views/SlideFloatingImages.vue'),
    [SlideType.HEADING]: () => import('./views/SlideHeading.vue'),
    [SlideType.INTRO]: () => import('./views/SlideIntro.vue'),
    [SlideType.LAYOUT]: () => import('./views/SlideLayout.vue'),
    [SlideType.MEDIA]: () => import('./views/SlideMedia.vue'),
    [SlideType.MEDIA_GRID]: () => import('./views/SlideMediaGrid.vue'),
    [SlideType.PDF_VIEWER]: () => import('./views/SlidePdfViewer.vue'),
    [SlideType.SAMPLE_VIEWER]: () => import('./views/SlideSampleViewer.vue'),
    [SlideType.SECTION]: () => import('./views/SlideSection.vue'),
    [SlideType.TIMELINE_CAROUSEL]: () => import('./views/SlideTimelineCarousel.vue'),
    [SlideType.TIMELINE_CAROUSEL_ITEM]: () => import('./views/SlideTimelineCarouselItem.vue')
}

export default components
