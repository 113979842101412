enum MutationType {
    SLUG = 'slug',
    UI_COLOR = 'ui-color',
    PRESENTATION_IS_VISIBLE = 'presentation-is-visible',
    PRESENTATION_WALKER = 'presentation-walker',
    NUMBER_SLIDES = 'number-slides',
    SLIDE_INDEX = 'slide-index',
    SLIDE_TYPE = 'slide-type',
    ROUTES = 'routes',
    TRANSITION_IS_VISIBLE = 'transition-visible',
    TRANSITION_IS_ANIMATED = 'transition-leave',
    IS_LEADER = 'is-leader',
    IS_SLIDESHOW = 'is-slideshow',
    HAS_PRESENTING_LEADER = 'has-presenting-leader',
    IS_PRESENTING_LEADER = 'is-presenting-leader',
    WINDOW_WIDTH = 'window-width',
    ADD_ACTIVE_TOUR_SLIDE_INDEX = 'addActiveTourSlideIndex',
    REMOVE_ACTIVE_TOUR_SLIDE_INDEX = 'removeActiveTourSlideIndex',
    SCROLL_IS_DISABLED = 'scrollIsDisabled'
}

export default MutationType
