import Color from '@/constants/color'
import { RootState } from '@/@types/store'

const state: RootState = {
    slug: '',
    presentationWalker: null,
    uiColor: Color.DARK,
    presentationIsVisible: false,
    numberSlides: 0,
    transitionIsVisible: false,
    transitionIsAnimated: false,
    slideIndex: 0,
    slideType: '',
    routes: [],
    isLeader: false,
    isSlideshow: false,
    hasPresentingLeader: false,
    isPresentingLeader: false,
    windowWidth: window?.innerWidth || 0,
    activeTourSlideIndexes: []
}

export default state
