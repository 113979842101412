const BINPACKER_HEADER_MAGIC = 'BINP'
const BINPACKER_HEADER_LENGTH = 12
const BINPACKER_CHUNK_TYPE_JSON = 0x4e4f534a
const BINPACKER_CHUNK_TYPE_BINARY = 0x004e4942

interface BinPackResource {
    name: string
    mimeType: string
    bufferStart: number
    bufferEnd: number
}

interface ResolveImagesArray {
    (images: ImageBitmap[] | HTMLImageElement[]): void
}

interface RejectImagesArray {
    (error: Error): void
}

/* Safari and Edge polyfill for createImageBitmap
 * https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/createImageBitmap
 */
if (!('createImageBitmap' in window)) {
    // @ts-ignore
    window.createImageBitmap = async function(data: ImageBitmapSource): Promise<HTMLImageElement> {
        return new Promise(resolve => {
            let dataURL
            if (data instanceof Blob) {
                dataURL = URL.createObjectURL(data)
            } else if (data instanceof ImageData || data instanceof Image) {
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')
                if (ctx === null) {
                    throw new Error('Cannot create 2d canvas context')
                }
                canvas.width = data.width
                canvas.height = data.height
                if (data instanceof ImageData) {
                    ctx.putImageData(data, 0, 0)
                } else if (data instanceof Image) {
                    ctx.drawImage(data, 0, 0)
                }
                dataURL = canvas.toDataURL()
            } else {
                throw new Error('createImageBitmap does not handle the provided image source type')
            }
            const img = document.createElement('img')
            img.onload = function() {
                console.debug('Polyfill: load single blob image')
                resolve(img)
            }
            img.src = dataURL
        })
    }
}

/*
 * Fetch locks to prevent load twice same url if download is not finished yet
 */
const locks: Map<string, Promise<ArrayBuffer>> = new Map()
/*
 * Bitmap cache to prevent extracting Binpack twice
 */
const imagesCache: Map<string, ImageBitmap[] | HTMLImageElement[]> = new Map()

function convertUint8ArrayToString(array: Array<number> | Uint8Array) {
    let str = ''
    array.forEach((item: number) => {
        str += String.fromCharCode(item)
    })
    return str
}

function extractBinpack(
    arrayBuffer: ArrayBuffer,
    resolve: ResolveImagesArray,
    reject: RejectImagesArray,
    url: string
): void {
    let content: string | null = null
    let contentArray: Uint8Array
    let binaryChunk: ArrayBuffer
    let byteOffset = null
    let chunkIndex = 0
    let chunkLength = 0
    let chunkType = null

    const headerView = new DataView(arrayBuffer, BINPACKER_HEADER_LENGTH)
    const header = {
        magic: convertUint8ArrayToString(new Uint8Array(arrayBuffer, 0, 4)),
        version: headerView.getUint32(4, true),
        length: headerView.getUint32(8, true)
    }

    if (header.magic !== BINPACKER_HEADER_MAGIC) {
        throw new Error('Unsupported Binpacker header')
    }

    const chunkView = new DataView(arrayBuffer, BINPACKER_HEADER_LENGTH)

    while (chunkIndex < chunkView.byteLength) {
        chunkLength = chunkView.getUint32(chunkIndex, true)
        chunkIndex += 4

        chunkType = chunkView.getUint32(chunkIndex, true)
        chunkIndex += 4

        if (chunkType === BINPACKER_CHUNK_TYPE_JSON) {
            contentArray = new Uint8Array(arrayBuffer, BINPACKER_HEADER_LENGTH + chunkIndex, chunkLength)
            content = convertUint8ArrayToString(contentArray)
        } else if (chunkType === BINPACKER_CHUNK_TYPE_BINARY) {
            byteOffset = BINPACKER_HEADER_LENGTH + chunkIndex
            binaryChunk = arrayBuffer.slice(byteOffset, byteOffset + chunkLength)
        }
        chunkIndex += chunkLength
    }

    if (content === null) {
        throw new Error('JSON content not found')
    }

    const jsonChunk = JSON.parse(content) as BinPackResource[]
    const promiseArray = jsonChunk.map(
        (entry: BinPackResource) =>
            new Promise((resolve, reject) => {
                if (entry.name && entry.mimeType) {
                    const matches = entry.name.match(/\d+/g)
                    if (matches && matches.length) {
                        const binary = binaryChunk.slice(entry.bufferStart, entry.bufferEnd)
                        const blob = new Blob([new Uint8Array(binary)], {
                            type: entry.mimeType
                        })

                        if (self.createImageBitmap === undefined) {
                            reject(new Error(`createImageBitmap not supported in your browser`))
                        }

                        if (
                            (entry.mimeType === 'image/webp' ||
                                entry.mimeType === 'image/png' ||
                                entry.mimeType === 'image/jpeg' ||
                                entry.mimeType === 'image/gif') &&
                            self.createImageBitmap !== undefined
                        ) {
                            try {
                                const bitmapPromise = createImageBitmap(blob)
                                if (bitmapPromise) {
                                    bitmapPromise
                                        .then(imageBitmap => {
                                            resolve(imageBitmap)
                                        })
                                        .catch(error => {
                                            reject(error)
                                        })
                                } else {
                                    reject(new Error(`createImageBitmap can't decode blob from ${url}.`))
                                }
                            } catch (exception) {
                                reject(exception)
                            }
                        } else {
                            reject(new Error(`Mimetype ${entry.mimeType} not supported`))
                        }
                    }
                }
            })
    ) as Array<Promise<ImageBitmap>>

    Promise.all(promiseArray)
        .then((imageBitmaps: ImageBitmap[] | HTMLImageElement[]) => {
            resolve(imageBitmaps)
        })
        .catch(error => {
            reject(error)
        })
}

/**
 * Extract a binpack resource to a canvas.
 *
 * @param url
 */
export default function loadBinpack(url: string): Promise<ImageBitmap[] | HTMLImageElement[]> {
    if (imagesCache.has(url)) {
        return new Promise<ImageBitmap[] | HTMLImageElement[]>(resolve => {
            resolve(imagesCache.get(url))
        })
    }

    const imagesPromise: Promise<ImageBitmap[] | HTMLImageElement[]> = new Promise((resolve, reject) => {
        if (locks.has(url)) {
            const promise = locks.get(url)
            if (promise) {
                promise
                    .then(arrayBuffer => {
                        extractBinpack(arrayBuffer, resolve, reject, url)
                    })
                    .catch(error => {
                        reject(error)
                    })
            }
        } else {
            const promise: Promise<ArrayBuffer> = new Promise((resolveBuffer, rejectBuffer) => {
                window.fetch(url).then(data => {
                    data.arrayBuffer()
                        .then((arrayBuffer: ArrayBuffer) => {
                            resolveBuffer(arrayBuffer)
                        })
                        .catch(error => {
                            rejectBuffer(error)
                        })
                })
            })
            locks.set(url, promise)
            promise
                .then((arrayBuffer: ArrayBuffer) => {
                    locks.delete(url)
                    extractBinpack(arrayBuffer, resolve, reject, url)
                })
                .catch(error => {
                    reject(error)
                })
        }
    })
    imagesPromise.then(images => {
        if (images.length) {
            imagesCache.set(url, images)
        }
    })

    return imagesPromise
}
