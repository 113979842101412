import { Module } from 'vuex'
import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { RemoteState, RootState } from '@/@types/store'

const remote: Module<RemoteState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

export default remote
