




















import Component from 'vue-class-component'
import { Getter, namespace } from 'vuex-class'
import { RouteConfig } from 'vue-router'
import ModuleNamespace from '@/constants/module-namespace'
import Vue from 'vue'
import MutationRemoteType from '@/constants/mutation-remote-type'
import ChevronRight from '@/assets/img/icons/chevron-right.svg?sprite'
import { Prop, Watch } from 'vue-property-decorator'
import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { trimSlashes } from '@/utils/string'

gsap.registerPlugin(Draggable, ScrollToPlugin)

const remoteModule = namespace(ModuleNamespace.REMOTE)

interface Item {
    label: string
    path: string
}

@Component({
    components: { ChevronRight }
})
export default class NavBreadcrumbs extends Vue {
    @Prop({ default: true }) allowTransition!: boolean

    @remoteModule.State navPath!: string
    @remoteModule.State navIsOpen!: boolean

    @Getter navRoutes!: Array<RouteConfig>

    $refs!: {
        inner: HTMLElement
        items: HTMLElement[]
    }

    drag!: Draggable
    timeline!: GSAPTimeline

    get items(): Item[] {
        const firstItem = {
            label: 'Menu',
            path: ''
        }
        const paths = trimSlashes(this.navPath).split('/')
        const items = []

        let routes: RouteConfig[] | undefined = this.navRoutes
        let rootPath = ''

        for (let i = 0, n = paths.length; i < n; i++) {
            const path = (i === 0 ? '/' : '') + paths[i]
            const route: RouteConfig | undefined = routes?.find(route => route.path === path)

            if (!route) break

            rootPath += (i !== 0 ? '/' : '') + route.path
            routes = route.children

            items.push({
                label: route.meta?.title,
                path: rootPath
            })

            if (!routes) break
        }

        return [firstItem, ...items]
    }

    get listTransitionName() {
        return this.allowTransition ? 'list' : ''
    }

    mounted() {
        this.createDrag()
    }

    beforeDestroy() {
        this.disposeDrag()
    }

    createDrag() {
        this.drag = Draggable.create(this.$refs.inner, {
            type: 'scrollLeft'
        })[0]
    }

    disposeDrag() {
        if (this.drag) this.drag.kill()
    }

    enter() {
        const items = this.$el.querySelectorAll('.' + this.$style.itemButton)

        if (this.timeline) this.timeline.kill()

        this.timeline = gsap
            .timeline()
            .set(this.$refs.inner, { opacity: 0 })
            .set(this.$refs.inner, { opacity: 1 }, 0.4)
            .fromTo(
                items,
                {
                    opacity: 0,
                    x: 80
                },
                {
                    opacity: 1,
                    x: 0,
                    delay: 0.4,
                    duration: 0.8,
                    ease: 'power3.out',
                    stagger: 0.05
                },
                0
            )
    }

    leave() {
        const items = this.$el.querySelectorAll('.' + this.$style.itemButton)

        if (this.timeline) this.timeline.kill()

        this.timeline = gsap
            .timeline()
            .to(items, {
                opacity: 0,
                duration: 0.4
            })
            .set(this.$refs.inner, { opacity: 0 })
    }

    onItemButtonClick(item: Item) {
        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.NAV_PATH, item.path)
    }

    @Watch('items')
    onItemsChange() {
        this.$nextTick(() => {
            const width = this.$refs.inner.offsetWidth
            const lastItem = this.$refs.items[this.items.length - 1]
            const lastItemRight = lastItem.offsetLeft + lastItem.offsetWidth
            const x = lastItemRight - width

            gsap.to(this.$refs.inner, {
                scrollTo: { x },
                delay: 0.2,
                duration: 0.8,
                ease: 'power2.inOut'
            })
            // this.$refs.items[this.items.length - 1]?.scrollIntoView?.({
            //     behavior: 'smooth'
            // })
        })
    }

    @Watch('navIsOpen')
    onNavIsOpenChange() {
        this.$nextTick(() => {
            if (this.navIsOpen) this.enter()
            else this.leave()
        })
    }
}
