


























import Vue from 'vue'

export default Vue.extend({
    props: {
        ordered: Boolean,
        shadow: Boolean,
        active: Boolean,
        visited: Boolean,
        complete: Boolean
    }
})
