

















import Component from 'vue-class-component'
import Vue from 'vue'
import gsap from 'gsap'
import { Route } from 'vue-router'
import SlideType from '@/constants/slide-type'
import SplitText from 'gsap/SplitText'
import { Getter, State } from 'vuex-class'
import MutationType from '@/constants/mutation-type'

const MIN_DURATION = 1200
const SKIP = process.env.VUE_APP_SKIP_TRANSITION === '1'

gsap.registerPlugin(SplitText)

@Component
export default class TransitionScreen extends Vue {
    @State slideIndex!: number
    @State isLeader!: boolean

    @Getter getSectionIndexByPath!: Function

    sectionIndex = -1
    title = ''
    subtitle = ''
    isFirstRoute = true
    hideContext = false

    startTime!: number
    $refs!: {
        overlay: HTMLElement
        background: HTMLElement
        sectionIndex: HTMLElement
        title: HTMLElement
        subtitle: HTMLElement
    }
    timeline!: GSAPTimeline
    splitTitle!: SplitText

    get formattedSectionIndex() {
        return this.sectionIndex > -1 ? this.sectionIndex + 1 : ''
    }

    get hiddenContextStyle() {
        return { visibility: 'hidden' }
    }

    created() {
        this.$router.beforeEach(this.beforeRouteChange)
        this.$router.beforeResolve(this.beforeRouteResolve)
        this.$router.afterEach(this.afterRouteChange)
    }

    beforeRouteChange(to: Route, from: Route, next: Function) {
        const isCarouselCollections =
            from.meta?.parentIndex === to.meta?.parentIndex &&
            from.meta?.type === SlideType.COLLECTION &&
            to.meta?.type === SlideType.COLLECTION &&
            this.slideIndex === 0

        if (this.isFirstRoute || SKIP || isCarouselCollections || to.path === from.path) {
            next()
        } else {
            this.startTime = Date.now()

            this.sectionIndex = this.getSectionIndexByPath(to.path)
            this.title = to.meta?.parentTitle || to.meta?.title
            this.subtitle = to.meta?.parentTitle ? to.meta?.title : ''
            this.hideContext = !this.isLeader && to.meta?.isShadowContent

            // wait next tick for splitting title
            this.$nextTick(() => {
                this.enter().then(() => {
                    next()
                })
            })
        }
    }

    beforeRouteResolve(to: Route, from: Route, next: Function) {
        if (this.isFirstRoute || SKIP) {
            next()
        } else {
            const elapsedTime = Date.now() - this.startTime
            // wait extra 100 ms to allow the application to do some work
            // like close the nav for example or initialize the new route view
            const delay = Math.max(MIN_DURATION - elapsedTime, 0) + 100

            next()

            setTimeout(() => {
                this.leave()
            }, delay)
        }
    }

    afterRouteChange() {
        this.isFirstRoute = false
    }

    enter() {
        if (this.timeline) this.timeline.kill()

        const splitTitle = new SplitText('.' + this.$style.title, { type: 'chars,words' })
        const contentElements = [this.$refs.title]

        if (this.hideContext) {
            gsap.set([this.$refs.sectionIndex, this.$refs.subtitle], { opacity: 0 })
        } else {
            contentElements.unshift(this.$refs.sectionIndex)
            contentElements.push(this.$refs.subtitle)
        }

        this.$store.commit(MutationType.TRANSITION_IS_ANIMATED, true)

        this.timeline = gsap
            .timeline({ onComplete: this.afterEnter })

            .set(this.$el, { visibility: 'inherit' }, 0)

            .fromTo(
                this.$refs.overlay,
                {
                    opacity: 0
                },
                {
                    opacity: 1,
                    duration: 1
                },
                0
            )

            .fromTo(
                this.$refs.background,
                {
                    scaleX: 0,
                    xPercent: 100
                },
                {
                    scaleX: 1,
                    xPercent: 0,
                    duration: 1,
                    ease: 'power3.inOut'
                },
                0
            )

            .fromTo(
                contentElements,
                {
                    x: 60,
                    opacity: 0
                },
                {
                    x: 0,
                    opacity: 1,
                    duration: 1,
                    ease: 'power3',
                    stagger: 0.07
                },
                0.5
            )

            .fromTo(
                splitTitle.chars,
                {
                    x: 30
                },
                {
                    x: 0,
                    duration: index => {
                        return index / this.title.length
                    },
                    ease: 'power3'
                },
                0.5
            )

        return this.timeline
    }

    afterEnter() {
        this.$store.commit(MutationType.TRANSITION_IS_ANIMATED, false)
        this.$store.commit(MutationType.TRANSITION_IS_VISIBLE, true)
    }

    leave() {
        if (this.timeline) this.timeline.kill()

        this.$store.commit(MutationType.TRANSITION_IS_ANIMATED, true)
        this.$store.commit(MutationType.TRANSITION_IS_VISIBLE, false)

        this.timeline = gsap
            .timeline({
                onComplete: this.afterLeave
            })

            .to(
                this.$refs.background,
                {
                    scaleX: 0,
                    xPercent: 0,
                    duration: 1,
                    ease: 'power3.inOut'
                },
                0
            )

            .to(
                this.$refs.overlay,
                {
                    opacity: 0,
                    duration: 1
                },
                0.3
            )

            .to(
                [this.$refs.sectionIndex, this.$refs.title, this.$refs.subtitle],
                {
                    x: -100,
                    opacity: 0,
                    duration: 0.8,
                    ease: 'power3.inOut'
                },
                0
            )

            .set(this.$el, { visibility: '' })

        return this.timeline
    }

    afterLeave() {
        this.$store.commit(MutationType.TRANSITION_IS_ANIMATED, false)
    }
}
