enum EventType {
    GOTO_NEXT_SLIDE = 'goto-next-slide',
    GOTO_PREVIOUS_SLIDE = 'goto-previous-slide',
    RESIZE = 'resize',
    ASSETS_LOADED = 'assets-loaded',
    SOCKET_DISCONNECT = 'disconnect',
    START_PRESENTING = 'start-presenting',
    STOP_PRESENTING = 'leave-presenting',
    REMOTE_MUTATION = 'remote-mutation',
    LEADER_PRESENTING_START = 'leader-presenting-start',
    LEADER_PRESENTING_STOP = 'leader-presenting-stop',
    PRESENTING_LEADER_DISCONNECT = 'presenting-leader-disconnect',
    REQUEST_REMOTE_STATE = 'request-remote-state',
    COPY_REMOTE_STATE = 'copy-remote-state',
    MOUNTED = 'mounted',
    START_TOUR = 'start-tour'
}

export default EventType
